<template>
	<app-modal :show="show" @close="$emit('close')" :title="modalTitle">
		<div v-if="item">
			<component
				v-bind:is="config.modal_componentName"
				slot="card-content"
				:item="item"
				@close="$emit('close', $event)"
			></component>
		</div>
	</app-modal>
</template>

<script>
import EventModalContent from './EventModalContent.vue';
import PollModalContent from './PollModalContent.vue';
import MeetRequestModalContent from './MeetRequestModalContent.vue';
import GoalModalContent from './GoalModalContent.vue';
import AppModal from '@/components/modals/Modal.vue';

import { itemConfig } from '@/services/cardConfig';

export default {
	components: {
		EventModalContent,
		PollModalContent,
		MeetRequestModalContent,
		GoalModalContent,
		AppModal,
	},
	props: {
		show: {
			type: Boolean,
		},
		item: {
			type: Object,
		},
	},
	computed: {
		config() {
			if (!this.item) return;
			return itemConfig[this.item.__typename];
		},
		modalTitle() {
			if (!this.config) return '';
			return `${this.config.modal_title}${this.first_name}`;
		},
		first_name() {
			return this.item.__typename === 'Person'
				? ` ${this.item.first_name}`
				: '';
		},
	},
};
</script>

<style scoped></style>
