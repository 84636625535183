<template>
	<cs-button class="action-bar-button" fill="clear" corners="straight">
		<i
			:class="[
				icon,
				{ 'action-bar-button__icon--upside-down': upsideDownIcon },
			]"
			class="action-bar-button__icon"
		/>
		<div class="cs-textstyle-paragraph-small-bold action-bar-button__title">
			{{ title }}
		</div>
	</cs-button>
</template>
<script>
export default {
	props: {
		icon: {
			type: String,
			required: true,
		},
		upsideDownIcon: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
	},
};
</script>
<style scoped>
.action-bar-button {
	--cs-button-padding: 0;
}
.action-bar-button >>> .cs-button__content {
	display: unset;
	text-align: center;
}
.action-bar-button >>> .cs-button__content i {
	display: inline-block;
	font-size: 30px;
	height: 40px;
}
.action-bar-button__icon--upside-down {
	transform: rotate(180deg);
}
.action-bar-button .action-bar-button__title {
	padding-left: 0;
}
</style>
