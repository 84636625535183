<template>
  <cs-button
    class="action-bar__primary"
    :class="{ whiteBackground: buttonFill === 'outline' }"
    v-if="primaryButtonTitle"
    block
    :fill="buttonFill"
    corners="rounded"
    :disabled="primaryButtonDisabled"
    @click="executePrimaryAction"
    >{{ primaryButtonTitle }}</cs-button
  >
</template>

<script>
import { mapGetters } from 'vuex';
import { itemConfig } from '@/services/cardConfig';
import { invokePrimaryAction } from '@/services/api';
import { analytics } from '@/services/utils';

export default {
  props: {
    card: {
      type: Object,
      default: null,
    },
    fill: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('cards', {
      getCardInput: 'getCardInput',
      getCardUserResponse: 'getCardUserResponse',
    }),
    buttonFill() {
      if (this.fill) return this.fill;
      if (this.cardConfig.getCardActionLabel) {
        if (this.cardConfig.getCardActionLabel(this.card).changed === true) {
          return 'outline';
        } else return 'solid';
      }
      return 'solid';
    },
    primaryButtonDisabled() {
      if (!this.cardConfig) return true;
      if (this.cardConfig.disablePrimaryUnlessInput) {
        return !this.currentInput;
      }
      return false;
    },
    primaryButtonTitle() {
      if (!this.cardConfig) return '';

      // Get the correct label for Event, Poll, Goal (the cards which have a user input)
      if (this.cardConfig.getCardActionLabel) {
        return this.cardConfig.getCardActionLabel(this.card).label;
      }
      return this.cardConfig.card_primaryButtonLabel;
    },

    // Returns the config info for the currently selected recommendation item
    // See @/services/config
    cardConfig() {
      if (!this.card) return;
      return itemConfig[this.card.__typename];
    },
    currentInput() {
      if (!this.card) return;
      return this.getCardInput(this.card.id);
    },
    currentResponse() {
      if (!this.card) return;
      return this.getCardUserResponse(this.card.id);
    },
  },
  methods: {
    async executePrimaryAction() {
      const config = this.cardConfig;
      if (!this.card || !config || !config.primaryCardAction) return;

      // TODO: try to handle the card inputs from their respective configs
      this.card.cardInput = !this.currentInput
        ? this.card.cardInput
        : this.currentInput;

      // if the user has already answered and has data, and presses again
      // on the primary button, it is for changing the data => remove it
      // we use this to trigger the re-render of DOM
      if (config.card_removeCardResponseOnToggle && this.currentInput) {
        this.$store.dispatch('cards/removeCardInput', { card: this.card });
        this.$store.dispatch('cards/removeCardUserResponse', {
          card: this.card,
        });
        config.triggerChangeAnswer()
        this.$set(this.card, 'values', null);
        this.$set(this.card, 'cardInput', null);
      } else {
        // if there is no user data, it means we should continue with
        // the primary action and we handle there what happens in case it's false
        config.primaryCardAction(this.card);
        invokePrimaryAction(this.$apollo, this.card);
      }
      analytics({eventName: 'card_primary-button-click',label: config.gtag_label})
    },
  },
};
</script>
<style scoped>
.action-bar__primary.cs-button.cs-button--outline.whiteBackground {
  background-color: white;
}
</style>
