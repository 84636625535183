<template>
	<app-action-bar-button
		:icon="iconClass"
		@click.stop="toggleFollow"
		title="See more"
	/>
</template>
<script>
import { mapGetters } from 'vuex';
import $toast from '@/services/toast';
import AppActionBarButton from '@/components/alternate/ActionBarButton.vue';

export default {
	components: {
		AppActionBarButton,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
		icon: {
			type: String,
			default: 'cs-icons-bookmark',
		},
		warnUnsave: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters('auth', ['userHasSavedItem']),
		isSaved() {
			return this.userHasSavedItem(this.item.id);
		},
		iconClass() {
			return this.isSaved ? `${this.icon}-filled` : this.icon;
		},
	},
	methods: {
		toggleFollow() {
			this.isSaved ? this.unsaveCard() : this.saveCard();
		},
		saveCard() {
			this.$store.dispatch('auth/saveItem', this.item);
		},
		async unsaveCard() {
			this.$store.dispatch('auth/unsaveItem', this.item);
			if (this.warnUnsave) {
				const response = await $toast.show({
					message: 'Removed from saved cards',
					button: 'Undo',
				});
				if (response && response === 'Undo') this.saveCard();
			}
		},
	},
};
</script>
