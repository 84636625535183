import { getApolloClient } from '@/vue-apollo';
import setPollResponse from '@/graphql/polls/setPollResponse.gql';
import getPoll from '@/graphql/polls/getPoll.gql';
import {analytics} from '@/services/utils.js'

import $bus from '@/services/bus';

import $dialog from '@/services/dialog';
import { store as $store } from '@/store';

const pollConfig = {
  card_componentName: 'PollCard',
  card_title: 'Poll',
  card_icon: 'cs-icons-poll',
  card_primaryButtonLabel: 'Vote',
  card_item_query: getPoll,
  card_item_query_key: 'poll',

  modal_componentName: 'PollModalContent',
  modal_title: 'View Results',
  disablePrimaryUnlessInput: true,
  gtag_label: 'poll-card',

  share_props: {
    title: 'title',
  },

  getCardActionLabel: (card) => {
    const isVoted = $store.getters['auth/userVotedPoll'](card.id);
    if (isVoted) return { label: 'Update choice', changed: true };
    return { label: 'Vote', changed: false };
  },

  primaryCardAction: async (card) => {
    const isVoted = $store.getters['auth/userVotedPoll'](card.id);
    if (isVoted) { 
      analytics({eventName: 'poll_update-choice-button-click'})
    }
    const result = await getApolloClient()
      .mutate({
        mutation: setPollResponse,
        variables: {
          pollOptionId: card.cardInput[0].value,
        },
      })
      .catch((error) => {
        console.log(error);
      });

    if (result) {
      $bus.$emit('open-primary-action-modal', card);
    } else {
      $dialog.alert(
        'There was an error executing the last action. Please try again.'
      );
    }
  },
  
};

export default pollConfig;
