<template>
	<div class="tutorial-popover">
		<!-- Steps -->
		<div class="cs-textstyle-paragraph-small tutorial-popover__steps">
			Step {{ step }} of {{ totalSteps }}
		</div>

		<!-- Message -->
		<div class="cs-textstyle-paragraph-small tutorial-popover__message">
			{{ message }}
		</div>

		<!-- Action Buttons -->
		<div class="tutorial-popover__action-buttons">
			<cs-button
				class="tutorial-popover__action-button"
				fill="clear"
				variant="default"
				@click="skip"
				>Skip</cs-button
			>
			<cs-button
				class="tutorial-popover__action-button"
				fill="clear"
				variant="primary"
				@click="next"
				>Next</cs-button
			>
		</div>
		<div
			v-if="pointerPosition !== 'none'"
			class="tutorial-popover__pointer"
			:class="[
				`tutorial-popover__pointer--first-${firstPointerPosition}`,
				`tutorial-popover__pointer--second-${secondPointerPosition}`,
			]"
		></div>
	</div>
</template>
<script>
export default {
	props: {
		message: {
			type: String,
			default: '',
		},
		step: {
			type: Number,
			default: 1,
		},
		totalSteps: {
			type: Number,
			default: 1,
		},
		pointerPosition: {
			type: String,
			default: 'none',
			validator(x) {
				const positions = [
					'none',
					'top-center',
					'top-left',
					'top-right',
					'bottom-center',
					'bottom-left',
					'bottom-right',
					'left-center',
					'left-top',
					'left-bottom',
					'right-center',
					'right-top',
					'right-bottom',
				];
				return positions.indexOf(x) > -1;
			},
		},
	},
	computed: {
		firstPointerPosition() {
			return this.pointerPosition.split('-')[0];
		},
		secondPointerPosition() {
			return this.pointerPosition.split('-')[1];
		},
	},
	methods: {
		skip() {
			this.$emit('skip');
		},
		next() {
			this.$emit('next');
		},
	},
};
</script>
<style scoped>
.tutorial-popover {
	--tutorial-popover-background-color: #e1ecff;
	--tutorial-popover-padding: 16px;
	background-color: var(--tutorial-popover-background-color);
	width: 240px;
	box-sizing: border-box;
	border-radius: 3px;
	padding: var(--tutorial-popover-padding);
	position: fixed;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 6px;
}
.tutorial-popover__steps {
	color: var(--cs-gray-04);
}
.tutorial-popover__message {
	color: var(--cs-gray-06);
	padding-bottom: 16px;
}
.tutorial-popover__action-buttons {
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.tutorial-popover__action-button {
	--cs-button-padding: var(--tutorial-popover-padding);
	margin: calc(-1 * var(--tutorial-popover-padding));
}

/* Popover Pointer (triangle) */
.tutorial-popover__pointer {
	position: absolute;
	--tutorial-popover-pointer-height: 7px;
	--tutorial-popover-pointer-width-half: 6px;
	width: 0;
	height: 0;
}

/* Position and dimensions */
.tutorial-popover__pointer--first-top {
	top: calc(-1 * var(--tutorial-popover-pointer-height));
	border-left: var(--tutorial-popover-pointer-width-half) solid transparent;
	border-right: var(--tutorial-popover-pointer-width-half) solid transparent;
	border-bottom: var(--tutorial-popover-pointer-height) solid
		var(--tutorial-popover-background-color);
}
.tutorial-popover__pointer--first-bottom {
	bottom: calc(-1 * var(--tutorial-popover-pointer-height));
	border-left: var(--tutorial-popover-pointer-width-half) solid transparent;
	border-right: var(--tutorial-popover-pointer-width-half) solid transparent;
	border-top: var(--tutorial-popover-pointer-height) solid
		var(--tutorial-popover-background-color);
}
.tutorial-popover__pointer--first-left {
	left: calc(-1 * var(--tutorial-popover-pointer-height));
	border-top: var(--tutorial-popover-pointer-width-half) solid transparent;
	border-bottom: var(--tutorial-popover-pointer-width-half) solid transparent;
	border-right: var(--tutorial-popover-pointer-height) solid
		var(--tutorial-popover-background-color);
}
.tutorial-popover__pointer--first-right {
	right: calc(-1 * var(--tutorial-popover-pointer-height));
	border-top: var(--tutorial-popover-pointer-width-half) solid transparent;
	border-bottom: var(--tutorial-popover-pointer-width-half) solid transparent;
	border-left: var(--tutorial-popover-pointer-height) solid
		var(--tutorial-popover-background-color);
}

/* Alignment */
.tutorial-popover__pointer--first-top.tutorial-popover__pointer--second-center,
.tutorial-popover__pointer--first-bottom.tutorial-popover__pointer--second-center {
	left: 50%;
	margin-left: calc(-1 * var(--tutorial-popover-pointer-width-half));
}
.tutorial-popover__pointer--first-left.tutorial-popover__pointer--second-center,
.tutorial-popover__pointer--first-right.tutorial-popover__pointer--second-center {
	top: 50%;
	margin-top: calc(-1 * var(--tutorial-popover-pointer-width-half));
}
.tutorial-popover__pointer--second-top {
	top: var(--tutorial-popover-padding);
}
.tutorial-popover__pointer--second-bottom {
	bottom: var(--tutorial-popover-padding);
}
.tutorial-popover__pointer--second-left {
	left: var(--tutorial-popover-padding);
}
.tutorial-popover__pointer--second-right {
	right: var(--tutorial-popover-padding);
}
</style>
