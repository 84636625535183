<template>
	<div class="goal-card">
		<single-answers @click.stop :question="mainQuestion" @input="onInput"></single-answers>
		<p v-if="userInput" class="cs-textstyle-paragraph-bold">
			{{userInput === 'true' ? 'Yes' : 'No'}}
		</p>
		<answer-summary
			v-if="answers && answers.length"
			:answers="answers"
		></answer-summary>
	</div>
</template>

<script>
import AnswerSummary from '../questions/AnswerSummary.vue';
import SingleAnswers from '../questions/SingleAnswers.vue';

import { mapGetters } from 'vuex';
import { analytics } from '@/services/utils';

export default {
	components: { SingleAnswers, AnswerSummary },
	props: {
		card: {
			type: Object,
		},
	},
	computed: {
		...mapGetters('cards', {
			getCardUserResponse: 'getCardUserResponse',
			getCardInput: 'getCardInput',
		}),
		mainQuestion() {
			return {
				title: this.card.question,
				choiceType: 'single',
				componentName: 'SingleAnswers',
				required: true,
				options:
					this.userInput
						? []
						: [
								{ label: 'Yes', value: true },
								{ label: 'No', value: false },
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  ],
				value: null,
			};
		},
		answers() {
			if (!this.card) return null;
			return this.getCardUserResponse(this.card.id);
		},
		userInput() {
			if (!this.card) return null;
			return this.getCardInput(this.card.id);
		}
	},
	methods: {
		async onInput($event) {
			this.$emit('input', $event);
			analytics({ eventName: 'goal_answer-goal-question', route: 'card-deck' })
		},
	},
};
</script>
