import getPerson from '@/graphql/people/getPerson.gql';

import $bus from '@/services/bus';

const personConfig = {
  card_componentName: 'PersonCard',
  card_title: 'Connection',
  card_icon: 'cs-icons-profile',
  card_primaryButtonLabel: 'Meet ',
  card_item_query: getPerson,
  card_item_query_key: 'person',

  modal_componentName: 'MeetRequestModalContent',
  modal_title: 'Meet',
  gtag_label: 'person-card',

  share_props: {
    title: 'first_name',
    message: 'summary',
  },

  primaryCardAction: async (card) => {
    $bus.$emit('open-primary-action-modal', card);
  },
};

export default personConfig;
