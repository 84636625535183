<template>
	<div class="card-deck page">
		<main-header :page-title="pageTitle" :title-icon="titleIcon" />

		<app-loader v-if="loading" />
		<template v-if="recommendations">
			<app-swiper
				:deck="recommendations"
				:value="currentIndex"
				@input="slideIndexChanged"
				@slides-ready="slidesReady"
				ref="swiper"
			>
				<app-swiper-slide
					v-for="(card, $index) in recommendations"
					:key="$index"
				>
					<app-card
						class="lo-card-deck__app-card"
						:card="card"
						v-if="config[card.__typename].card_componentName"
					>
						<component
							v-bind:is="config[card.__typename].card_componentName"
							@input="onInput"
							slot="card-content"
							:card="card"
						>
						</component>
						<app-card-primary-action-button
							slot="card-content"
							class="card-deck__action-button"
							:card="card"
							fill="outline"
						/>
					</app-card>
				</app-swiper-slide>
			</app-swiper>
			<app-action-bar
				:card="currentRecommendation"
				@next-button="nextSlide"
				@dislike-button="setItemRejected"
			/>
		</template>
	</div>
</template>

<script>
import setItemRejected from '@/graphql/item/setItemRejected.gql';
import $dialog from '@/services/dialog';

import AppCardPrimaryActionButton from '@/components/cards/CardPrimaryActionButton.vue';
import AppCard from '@/components/alternate/Card.vue';
import AppActionBar from '@/components/alternate/ActionBar.vue';

import PersonCard from '../components/cards/PersonCard.vue';
import PollCard from '../components/cards/PollCard.vue';
import EventCard from '../components/cards/EventCard.vue';
import ArticleCard from '../components/cards/ArticleCard.vue';
import GoalCard from '../components/cards/GoalCard.vue';
import MainHeader from '../components/general/MainHeader.vue';
import AppLoader from '@/components/general/Loader.vue';

import AppSwiper from '../components/general/Swiper.vue';
import AppSwiperSlide from '../components/general/SwiperSlide.vue';

import $notifications from '@/services/notifications';

import { setItemViewed, setItemNavigatedBack } from '@/services/api';
import { itemConfig } from '@/services/cardConfig';
import { mapState, mapActions, mapGetters } from 'vuex';
import $bus from '@/services/bus';

export default {
	components: {
		AppCardPrimaryActionButton,
		AppCard,
		PersonCard,
		PollCard,
		AppSwiper,
		AppSwiperSlide,
		MainHeader,
		AppActionBar,
		ArticleCard,
		EventCard,
		GoalCard,
		AppLoader,
	},
	mounted() {
		this.loadRecommendedCards();
		$notifications.setup();
		$bus.$on('next-card', () => {
			this.nextSlide();
		});
	},
	computed: {
		...mapState('cards', {
			currentIndex: 'index',
			recommendations: 'deck',
		}),
		...mapGetters('auth', ['userIsAttendingEvent']),
		loading() {
			return !this.recommendations;
		},
		pageTitle() {
			if (!this.currentConfig) return '';
			return this.currentConfig.card_title;
		},
		titleIcon() {
			if (!this.currentConfig) return '';
			return this.currentConfig.card_icon;
		},
		// Returns true if the recommendation items are loaded and the glide component is ready
		contentReady() {
			return this.recommendations && this.currentIndex > -1;
		},
		// Returns the currently selected recommendation item
		currentRecommendation() {
			if (!this.contentReady) return;
			return this.recommendations[this.currentIndex];
		},
		// Returns the ID of the currently selected recommendation item
		currentId() {
			if (!this.currentRecommendation) return;
			return this.currentRecommendation.id;
		},
		// Returns the config info for the currently selected recommendation item
		// See @/services/config
		currentConfig() {
			if (!this.currentRecommendation) return;
			return this.config[this.currentRecommendation.__typename];
		},
		hasUserData() {
			return (
				this.currentRecommendation &&
				this.currentRecommendation.values &&
				this.currentRecommendation.values.length
			);
		},
		cardConfig() {
			return (card) => {
				return this.config[card.__typename];
			};
		},
	},

	data() {
		return {
			currentCard: {},
			currentCardId: '',
			config: itemConfig,
			primaryActionModalOpen: false,
			startTime: new Date().getTime(),
			currentInput: null,
			visitedItems: [],
			timeout: null,
			newRecsLoading: false,
		};
	},
	methods: {
		...mapActions('cards', {
			setIndex: 'setIndex',
			loadRecommendedCards: 'loadRecommended',
			loadNewRecommendedSet: 'loadNewRecommendedSet',
		}),
		prevSlide() {
			this.$refs.swiper.prev();
		},
		nextSlide() {
			this.$refs.swiper.next();
		},
		slideIndexChanged(newIndex, oldIndex) {
			console.log('slide index changed');
			this.setIndex(newIndex);
			this.recommendations &&
				this.$route.fullPath !== `/card?id=${this.currentId}` &&
				this.$router.push({ query: { id: this.currentId } });

			this.visitedItemsCheck(newIndex);
			if (oldIndex !== undefined && newIndex !== oldIndex) {
				const endTime = new Date().getTime();
				setItemViewed(
					this.$apollo,
					this.recommendations[oldIndex].id,
					oldIndex,
					endTime - this.startTime
				);
				// Reset the start time for the next item
				this.startTime = new Date().getTime();
			}
			//if we are getting close to the end of the card deck, load the next batch
			if (newIndex == this.recommendations.length - 3) {
				this.newRecsLoading = true;
				this.loadNewRecommendedSet().then(() => {
					this.newRecsLoading = false;
					this.$forceUpdate();
				});
			}
		},
		slidesReady() {
			this.startTime = new Date().getTime();
		},
		onInput($event) {
			this.$store.dispatch('cards/setCardInput', {
				card: this.currentRecommendation,
				input: $event,
			});
			this.currentConfig &&
				this.currentConfig.onInput &&
				this.currentConfig.onInput($event, this.currentRecommendation);
		},
		visitedItemsCheck(newIndex) {
			if (this.timeout) clearTimeout(this.timeout);
			if (this.visitedItems.includes(newIndex)) {
				this.timeout = setTimeout(() => {
					setItemNavigatedBack(
						this.$apollo,
						this.recommendations[newIndex].id,
						true
					);
				}, 5000);
			} else {
				this.visitedItems.push(newIndex);
			}
		},
		async setItemRejected() {
			const card = this.currentRecommendation;
			const { data } = await this.$apollo
				.mutate({
					mutation: setItemRejected,
					variables: {
						rejected: true,
						itemId: card.id,
					},
				})
				.catch((error) => {
					console.log(error);
				});
			if (data) {
				$dialog
					.alert(
						'Thank you for your feedback! We are constantly improving the recommendations you see.'
					)
					.then(this.nextSlide);
			}
		},
	},
};
</script>

<style scoped>
.lo-card-deck__app-card {
	padding-left: var(--cs-page-margin-x);
	padding-right: var(--cs-page-margin-x);
}
</style>
