<template>
  <div class="article-card">
    <tag-list v-if="card.categories && card.categories.length" :tags="card.categories" :forceOneRow="true"></tag-list>
    <div class="source flexH ">
      <div class="article-card__date cs-textstyle-paragraph-small">{{createdDate}}</div>
    </div>
    <div class="title cs-textstyle-item-heading">{{card.title}}</div>
    <div class="section">
      {{card.content_snippet}}
    </div>
  </div>
</template>

<script>
import TagList from "../general/TagList.vue";

export default {
  components: {
    TagList,
  },
  computed: {
    createdDate() {
      const date = new Date(this.card.published_at);
      return date.toLocaleDateString('en-uk', {year:"numeric", month:"short", day:"numeric"})
    },
  },
  props: {
    card: {
      type: Object
    }
  },
  methods: {
  }, 
}
</script>

<style scoped>
.section {
  margin-bottom: 16px;
  margin-top: 16px;
}
.section .section-title{
  margin-bottom: 8px;
}
.title, .section-title {
  color: var(--cs-gray-06);
}
::v-deep .cs-profile .cs-profile__main {
  padding:0;
}
.flexH {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 12px;
}
i {
  font-size: 16px;
  margin-right: 8px;
}
.source {
  justify-content: space-between;
  align-items: center;
  margin: var(--cs-card-content-margin-y) 0px;
}
.source .cs-profile {
  width: 50%;
  margin-right: 20px;
}
.source-link {
  color: var(--cs-gray-06);
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 45%;
  text-align: right;
}
.article-card__date{
  color: var(--cs-gray-04);
}
</style>