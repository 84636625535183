import { itemConfig } from '@/services/cardConfig';
import { copyToClipboard } from '@/services/utils';

const share = (options) => {
  if (window.plugins && window.plugins.socialsharing) {
    const shareOptions = {
      url: options.url || '',
      message: options.message || options.url || '', // not supported on some apps (Facebook, Instagram)
      subject: options.title || '',
      // files: ['', ''], // an array of filenames either locally or remotely
      // iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
    };
    window.plugins.socialsharing.shareWithOptions(shareOptions);
    // NOTE: there are success/error handler functions, but they do not respond consistently when sharing
    // window.plugins.socialsharing.shareWithOptions(options, console.log, console.error);
  } else if (window.navigator.share) {
    navigator.share({
      'title': options.title || '',
      'text': options.message || '',
      'url': options.url || '',
    }).then(function() {
      // console.log('Successful share');
    }).catch(function() {
      // console.log('Error sharing:', error)
    });
  } else {
    copyToClipboard(options.url);
  }
};

const shareCard = (card) => {
  if (!card || !card.id || !card.__typename) return;
  const config = itemConfig[card.__typename];
  if (!config) return;
  const cardProps = config.share_props;
  if (!cardProps) return;
  
  const shareOptions = {};
  Object.keys(cardProps).forEach((key) => {
    shareOptions[key] = cardProps[key];
  });
  shareOptions.url = `https://leaders.org/cards/${card.id}`;
  share(shareOptions);
}

export { share, shareCard };
