<template>
  <div class="person-card">
    <app-profile
      :name="card.first_name + ' ' + card.last_name"
      :picture="card.photo_url"
      :location="card.current_city"
      :detail="card.occupation"
    />
    <div class="section" v-if="card.summary">
      <div class="cs-textstyle-informative-paragraph-bold section-title">
        About
      </div>
      <read-more
        v-if="card.summary"
        :text="card.summary"
        more-text="...Read More"
        less-text="Read Less"
        :limit="150"
      ></read-more>
    </div>
    <div class="section" v-if="card.interests.length">
      <div class="cs-textstyle-informative-paragraph-bold section-title">
        Interests
      </div>
      <tag-list
        v-if="card.interests && card.interests.length"
        :tags="card.interests.map((e) => e.interest)"
        :forceOneRow="false"
      ></tag-list>
    </div>
  </div>
</template>

<script>
import ReadMore from "../general/ReadMore.vue";
import TagList from "../general/TagList.vue";
import AppProfile from "@/components/general/AppProfile.vue";

export default {
  components: {
    ReadMore,
    TagList,
    AppProfile,
  },
  props: {
    card: {
      type: Object,
    },
  },
  methods: {},
};
</script>

<style scoped>
.section {
  margin-bottom: 16px;
}
.section .section-title {
  margin-bottom: 8px;
  color: var(--cs-gray-06);
}
</style>
