<template>
  <div class="signup page">
    <auth-layout>
      <img slot="image" src="../assets/img/LO_logo_gray.svg" />
      <div slot="text">
        <h1 class="cs-textstyle-page-title">Login with Invitation Code</h1>
        <p class="cs-textstyle-paragraph">
          Leaders.org an invitation-only network of influential leaders who help
          each other succeed. Please enter your invitation code below.
        </p>
      </div>
      <cs-input
        slot="fields"
        v-model="code"
        placeholder="Enter invitation code here"
      ></cs-input>
      <p slot="info">
        Don't have a code?
        <router-link to="request-invite">Request invitation</router-link>
      </p>
      <cs-button
        slot="buttons"
        corners="rounded"
        block
        :disabled="!code.length"
        @click="loginWithCode"
        >Continue</cs-button
      >
    </auth-layout>
  </div>
</template>

<script>
import AuthLayout from '../components/layouts/AuthLayout.vue';

import { mapActions } from 'vuex';
import { onLogin } from '../vue-apollo.js';
import { analytics } from '@/services/utils';

export default {
  components: { AuthLayout },
  data() {
    return {
      code: '',
    };
  },
  mounted() {
    analytics({eventName: 'login-code', route: 'splash'})
  },
  methods: {
    ...mapActions('auth', {
      claimInvitation: 'claimInvitation',
      updateUser: 'getCurrentUser',
    }),
    async loginWithCode() {
      await this.claimInvitation(this.code);
      await onLogin(
        this.$apollo.getClient(),
        localStorage.getItem('dlo_token')
      );
      await this.updateUser();
      this.$router.push('confirm-email');
    },
  },
};
</script>

<style scoped>
.splash {
  height: 100%;
}
</style>
