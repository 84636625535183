<template>
	<div class="lo-infinite-loader">
		<app-loader v-observe-visibility="options" v-if="!finished || paused" />
		<slot />
	</div>
</template>
<script>
import AppLoader from '@/components/general/Loader.vue';

export default {
	components: {
		AppLoader,
	},
	props: {
		paused: {
			type: Boolean,
			default: false,
		},
		finished: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			visible: false,
			options: {
				callback: this.visibilityChanged,
			},
		};
	},
	watch: {
		paused() {
			if (!this.paused && this.visible) {
				this.$emit('more');
			}
		},
	},
	methods: {
		visibilityChanged(isVisible) {
			this.visible = isVisible;
			if (!this.paused && isVisible) {
				this.$emit('more');
			}
		},
	},
};
</script>
