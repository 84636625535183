<template>
  <div class="card-deck page">
    <main-header :page-title="pageTitle" :title-icon="titleIcon"></main-header>

    <app-loader v-if="loading" />

    <template v-if="recommendations">
      <app-swiper
        :deck="recommendations"
        :value="currentIndex"
        @input="slideIndexChanged"
        @slides-ready="slidesReady"
        ref="swiper"
      >
        <app-swiper-slide
          v-for="(card, $index) in recommendations"
          :key="$index"
        >
          <app-card
            class="lo-card-deck__app-card"
            :card="card"
            v-if="config[card.__typename].card_componentName"
          >
            <component
              v-bind:is="config[card.__typename].card_componentName"
              @input="onInput"
              slot="card-content"
              :card="card"
              @click="cardClick"
            >
            </component>
          </app-card>
        </app-swiper-slide>
      </app-swiper>

      <app-action-bar
        :card="currentRecommendation"
        @back-button="prevSlide"
        :back-disabled="currentIndex === 0"
        @next-button="nextSlide"
        :next-disabled="currentIndex === recommendations.length - 1"
        :next-loading="newRecsLoading"
      />
    </template>
  </div>
</template>

<script>
import AppCard from '../components/cards/Card.vue';
import PersonCard from '../components/cards/PersonCard.vue';
import PollCard from '../components/cards/PollCard.vue';
import EventCard from '../components/cards/EventCard.vue';
import ArticleCard from '../components/cards/ArticleCard.vue';
import GoalCard from '../components/cards/GoalCard.vue';
import MainHeader from '../components/general/MainHeader.vue';
import AppActionBar from '../components/general/ActionBar.vue';
import AppLoader from '@/components/general/Loader.vue';

import AppSwiper from '../components/general/Swiper.vue';
import AppSwiperSlide from '../components/general/SwiperSlide.vue';

import $notifications from '@/services/notifications';

import { setItemViewed, setItemNavigatedBack } from '@/services/api';
import { itemConfig } from '@/services/cardConfig';
import { mapState, mapActions, mapGetters } from 'vuex';
import $bus from '@/services/bus';
import { analytics } from '@/services/utils';

export default {
  components: {
    AppCard,
    PersonCard,
    PollCard,
    AppSwiper,
    AppSwiperSlide,
    MainHeader,
    AppActionBar,
    ArticleCard,
    EventCard,
    GoalCard,
    AppLoader,
  },
  mounted() {
    this.loadRecommendedCards();
    $notifications.setup();
    $bus.$on('next-card', () => {
      this.nextSlide();
    });
  },
  computed: {
    ...mapState('cards', {
      currentIndex: 'index',
      recommendations: 'deck',
    }),
    ...mapGetters('auth', ['userIsAttendingEvent']),
    loading() {
      return !this.recommendations;
    },
    pageTitle() {
      if (!this.currentConfig) return '';
      return this.currentConfig.card_title;
    },
    titleIcon() {
      if (!this.currentConfig) return '';
      return this.currentConfig.card_icon;
    },
    // Returns true if the recommendation items are loaded and the glide component is ready
    contentReady() {
      return this.recommendations && this.currentIndex > -1;
    },
    // Returns the currently selected recommendation item
    currentRecommendation() {
      if (!this.contentReady) return;
      return this.recommendations[this.currentIndex];
    },
    // Returns the ID of the currently selected recommendation item
    currentId() {
      if (!this.currentRecommendation) return;
      return this.currentRecommendation.id;
    },
    // Returns the config info for the currently selected recommendation item
    // See @/services/config
    currentConfig() {
      if (!this.currentRecommendation) return;
      return this.config[this.currentRecommendation.__typename];
    },
    hasUserData() {
      return (
        this.currentRecommendation &&
        this.currentRecommendation.values &&
        this.currentRecommendation.values.length
      );
    },
    cardConfig() {
      return (card) => {
        return this.config[card.__typename];
      };
    },
  },

  data() {
    return {
      currentCard: {},
      currentCardId: '',
      config: itemConfig,
      primaryActionModalOpen: false,
      startTime: new Date().getTime(),
      currentInput: null,
      visitedItems: [],
      timeout: null,
      newRecsLoading: false,
    };
  },
  methods: {
    ...mapActions('cards', {
      setIndex: 'setIndex',
      loadRecommendedCards: 'loadRecommended',
      loadNewRecommendedSet: 'loadNewRecommendedSet',
    }),
    cardClick(){
      analytics({ eventName: 'card_card-click' })
    },
    prevSlide() {
      analytics({ eventName: 'card_back-button-click', label: this.currentConfig.gtag_label })
      this.$refs.swiper.prev();
    },
    nextSlide() {
      analytics({ eventName: 'card_next-button-click', label: this.currentConfig.gtag_label })
      this.$refs.swiper.next();
    },
    slideIndexChanged(newIndex, oldIndex) {
      console.log('slide index changed');
      this.setIndex(newIndex);
      this.recommendations &&
        this.$route.fullPath !== `/card?id=${this.currentId}` &&
        this.$router.push({ query: { id: this.currentId } });

      this.visitedItemsCheck(newIndex);
      if (oldIndex !== undefined && newIndex !== oldIndex) {
        const endTime = new Date().getTime();
        setItemViewed(
          this.$apollo,
          this.recommendations[oldIndex].id,
          oldIndex,
          endTime - this.startTime
        );
        // Reset the start time for the next item
        this.startTime = new Date().getTime();
      }
      //if we are getting close to the end of the card deck, load the next batch
      if (newIndex == this.recommendations.length - 3) {
        this.newRecsLoading = true;
        this.loadNewRecommendedSet().then(() => {
          this.newRecsLoading = false;
          this.$forceUpdate();
        });
      }
    },
    slidesReady() {
      this.startTime = new Date().getTime();
    },
    onInput($event) {
      this.$store.dispatch('cards/setCardInput', {
        card: this.currentRecommendation,
        input: $event,
      });
      this.currentConfig &&
        this.currentConfig.onInput &&
        this.currentConfig.onInput($event, this.currentRecommendation);
    },
    visitedItemsCheck(newIndex) {
      if (this.timeout) clearTimeout(this.timeout);
      if (this.visitedItems.includes(newIndex)) {
        this.timeout = setTimeout(() => {
          setItemNavigatedBack(
            this.$apollo,
            this.recommendations[newIndex].id,
            true
          );
        }, 5000);
      } else {
        this.visitedItems.push(newIndex);
      }
    },
  },
};
</script>

<style scoped>
.lo-card-deck__app-card {
  padding-left: var(--cs-page-margin-x);
  padding-right: var(--cs-page-margin-x);
}
</style>
