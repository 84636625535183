<template>
  <div class="login page">
    <auth-layout>
      <img slot="image" src="../assets/img/LO_logo_gray.svg" />
      <div slot="text">
        <h1 class="cs-textstyle-page-title">Login with Email</h1>
        <p class="cs-textstyle-paragraph">
          Welcome back to Leaders.org. Please submit your email address where
          you will receive an One Time Password (OTP).
        </p>
      </div>
      <cs-input
        slot="fields"
        v-model="email"
        placeholder="Submit email address"
      ></cs-input>
      <p slot="info">
        Have an invitation code?
        <router-link to="signup">Join here</router-link>
      </p>
      <cs-button
        slot="buttons"
        corners="rounded"
        block
        :disabled="!email.length"
        @click="sendOTPtoEmail"
        >Send OTP</cs-button
      >
    </auth-layout>
  </div>
</template>

<script>
import AuthLayout from '../components/layouts/AuthLayout.vue';
import sendOTP from '@/graphql/user/sendOTP.gql';
import { analytics } from '@/services/utils';

export default {
  components: { AuthLayout },
  data() {
    return {
      email: '',
    };
  },
  mounted() {
    analytics({eventName: 'login-email', route: 'splash'})
  },
  methods: {
    async sendOTPtoEmail() {
      if (!this.email.length) return;
      await this.sendOTP(this.email);
      this.$router.push({ path: 'check-otp', query: { email: this.email } });
    },
    async sendOTP(email) {
      return await this.$apollo
        .mutate({
          mutation: sendOTP,
          variables: {
            email: email,
          },
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.splash {
  height: 100%;
}
</style>
