<template>
  <div class="onboarding page">
    <cs-slides class="slides" @skip="skip" @finish="finish">
      <cs-slide>
        <cs-slide-image :src="onboarding1" />
        <cs-slide-header>Discovery by Leaders.org</cs-slide-header>
        <cs-slide-text
          ><strong>Leaders.org</strong> exists to help leaders connect with
          people, ideas, and opportunities that can help them
          succeed.</cs-slide-text
        >
      </cs-slide>
      <cs-slide>
        <cs-slide-image :src="onboarding2" />
        <cs-slide-header>Explore Opportunities</cs-slide-header>
        <cs-slide-text
          >This app will provide you with
          <strong>daily recommendations</strong> of people, ideas, and
          opportunities we think you should meet.</cs-slide-text
        >
      </cs-slide>
    </cs-slides>
  </div>
</template>

<script>
import onboarding1 from '@/assets/img/onboarding1.png';
import onboarding2 from '@/assets/img/onboarding2.png';
import { analytics } from '@/services/utils';
export default {
  data() {
    return {
      onboarding1,
      onboarding2,
    };
  },
  methods: {
    skip() {
      analytics({eventName: 'onboarding-skip', route: 'splash'})
      this.$router.push('/tutorial');
    },
    finish() {
      analytics({eventName: 'onboarding-complete', route: 'splash'})
      this.$router.push('/tutorial');
    },
  },
};
</script>

<style scoped>
.cs-slides.slides {
  --cs-slides-height: 90vh;
  --cs-slides-background: var(--cs-gray-01);
  margin: 20px 0px;
}
* >>> .cs-button__content {
  font-weight: 700;
}
* >>> .cs-slides__button-icon {
  font-weight: 700 !important;
}
</style>
