<template>
  <div class="saved-card">
    <div class="saved-card__first-row">
      <div class="saved-card__first-row-left">
        <cs-tag class="saved-card__tag">{{ card.typename }}</cs-tag>
        <div class="cs-textstyle-paragraph-extra-small saved-card__date">
          {{ createdDate }}
        </div>
      </div>
      <app-save-card-button :item="card" warn-unsave :config="config" />
    </div>
    <div class="saved-card__second-row">
      <div class="saved-card__second-row-info">
        <div
          v-if="card.title"
          class="text-overflow cs-textstyle-paragraph-small-bold"
        >
          {{ card.title }}
        </div>
        <div
          v-if="card.description"
          class="text-overflow cs-textstyle-paragraph-small"
        >
          {{ card.description }}
        </div>
      </div>
      <div v-if="card.photo_url" class="saved-card__second-row-picture">
        <img class="saved-card__picture" :src="card.photo_url" alt="" />
      </div>
    </div>
    <div class="saved-card__third-row" v-if="card.occupation && card.location">
      <div
        class="saved-card__occupation cs-textstyle-paragraph-small-bold text-overflow"
      >
        {{ card.occupation }}
      </div>
      <div
        class="saved-card__location cs-textstyle-paragraph-small text-overflow"
      >
        <i class="cs-icons-marker marker-icon"></i>{{ card.location }}
      </div>
    </div>
  </div>
</template>

<script>
import AppSaveCardButton from '@/components/cards/SaveCardButton.vue';
import { itemConfig } from '@/services/cardConfig';

export default {
  components: {
    AppSaveCardButton,
  },
  props: {
    card: {
      type: Object,
    },
  },
  computed: {
    config() {
      if (!this.card) return null;
      const typename = this.card.typename || this.card.__typename;
      if (!typename) return null;
      return itemConfig[typename];
    },
    createdDate() {
      const date = new Date(this.card.saved_at);
      return date.toLocaleDateString('en-uk', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
  },
};
</script>

<style scoped>
.saved-card {
  background: var(--cs-gray-00);
  margin-bottom: 10px;
  padding: 12px;
  border-radius: 3px;
}
.saved-card__tag {
  --cs-tag-color: #e1ecff;
  --cs-tag-text-color: var(--cs-primary-base);
  margin-right: 10px;
}
::v-deep .cs-tag__inner {
  font-weight: 700;
}
.saved-card__first-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.saved-card__first-row-left {
  display: flex;
  align-items: center;
}
.saved-card__date {
  color: var(--cs-gray-04);
}
.text-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  white-space: -webkit-pre-wrap;
  word-break: break-all;
  white-space: normal;
}
.saved-card__second-row {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.saved-card__picture {
  width: 45px;
  height: 40px;
  margin-left: 15px;
}
.saved-card__second-row-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.saved-card__third-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.marker-icon {
  margin-right: 4px;
}
.saved-card__location {
  color: var(--cs-gray-04);
}
</style>
