<template>
  <div id="app">
    <router-view></router-view>

    <!-- Modals -->
    <app-comments-modal
      v-if="selectedCardForComments"
      :show="showCommentsModal"
      @close="closeCommentsModal"
      :itemId="selectedCardForComments.id"
      :itemType="selectedCardForComments.__typename"
    />
    <!-- <app-interested-people-modal
      v-if="
        selectedCardForInterestedPeople &&
        selectedCardForInterestedPeople.interested_leaders
      "
      :show="showInterestedPeopleModal"
      :people-list="selectedCardForInterestedPeople.interested_leaders"
      @close="closeInterestedPeopleModal"
    /> -->
    <app-primary-action-modal
      v-if="selectedCard"
      :show="showPrimaryActionModal"
      :item="selectedCard"
      @close="closePrimaryActionModal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { onLogin } from './vue-apollo.js';

import { promiseTimeout } from '@/services/utils';

import $bus from '@/services/bus';

import AppCommentsModal from '@/components/modals/Comments.vue';
// import AppInterestedPeopleModal from '@/components/modals/InterestedPeopleList.vue';
import AppPrimaryActionModal from '@/components/modals/PrimaryActionModal.vue';

export default {
  name: 'App',
  components: {
    AppCommentsModal,
    // AppInterestedPeopleModal,
    AppPrimaryActionModal,
  },
  data() {
    return {
      showCommentsModal: false,
      // showInterestedPeopleModal: false,
      showPrimaryActionModal: false,
      selectedCard: null,
      selectedCardForComments: null,
      // selectedCardForInterestedPeople: null,
    };
  },
  async mounted() {
    $bus.$on('open-comments-modal', async (card) => {
      this.selectedCardForComments = card;
      await this.$nextTick();
      this.showCommentsModal = true;
    });
    // $bus.$on('open-interested-people-modal', async (card) => {
    //   this.selectedCardForInterestedPeople = card;
    //   await this.$nextTick();
    //   this.showInterestedPeopleModal = true;
    // });
    $bus.$on('open-primary-action-modal', async (card) => {
      this.selectedCard = card;
      await this.$nextTick();
      this.showPrimaryActionModal = true;
    });

    const accessToken = localStorage.getItem('dlo_token');
    if (!accessToken) {
      //TODO: CHANGE THIS WHEN WE IMPLEMENT FOR REAL USERS
      if (localStorage.getItem('test_invitation_code')) {
        await this.claimInvitation(
          localStorage.getItem('test_invitation_code')
        );
        await onLogin(
          this.$apollo.getClient(),
          localStorage.get_item('dlo_token')
        );
        await this.updateUser();
      } else if (this.$route.fullPath !== '/splash') {
        this.$router.push('splash');
      }
    } else {
      await this.updateUser();
    }
  },
  methods: {
    ...mapActions('auth', {
      updateUser: 'getCurrentUser',
      claimInvitation: 'claimInvitation',
    }),
    async closeCommentsModal() {
      this.showCommentsModal = false;
      await promiseTimeout(300);
      this.selectedCardForComments = null;
    },
    // async closeInterestedPeopleModal() {
    //   this.showInterestedPeopleModal = false;
    //   await promiseTimeout(300);
    //   this.selectedCardForInterestedPeople = null;
    // },
    async closePrimaryActionModal() {
      this.showPrimaryActionModal = false;
      await promiseTimeout(300);
      this.selectedCard = null;
    },
  },
};
</script>

<style>
html {
  height: 100%;
}
body {
  background-color: var(--cs-page-background-color);
  margin: 0;
  height: 100%;
}
.page {
  height: 100%;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--cs-gray-06);
  /* padding-top: var(--cs-page-margin-y-top); */
  /* height: 100vh; */
  box-sizing: border-box;
  /* padding-top: calc(16px + env(safe-area-inset-top));
	padding-bottom: calc(16px + env(safe-area-inset-bottom)); */
  height: 100%;
  padding: 16px 0;
}

a {
  text-decoration: none;
  color: var(--cs-primary-base);
  cursor: pointer;
}

/* cs-modal style [TODO: delete when we add these in cs-components] */
#app .cs-modal {
  --cs-modal-color: var(--cs-page-background-color);
}
#app .cs-modal > * {
  box-sizing: border-box;
}
#app .cs-modal .cs-modal__header-content {
  align-items: center;
}
</style>
