import Vue from 'vue';
const bus = new Vue();
export default bus;

// List of bus events:
// open-comments-modal
// open-interested-people-modal
// open-primary-action-modal
// unsave-card
// next-card
