<template>
	<div class="app-card">
		<div class="main-content">
			<div class="main-card">
				<cs-card>
					<img
						slot="media"
						v-if="config && card && config.card_hasMediaSlot && card.photo_url"
						:src="card.photo_url"
					/>
					<div slot="body">
						<div v-if="config && !config.card_isPrivate" class="header public">
							<!-- <div
								v-if="card.interested_leaders.length > 0"
								class="interests"
								@click="showInterestedPeople"
							>
								<cs-avatar-list
									:userList="userList"
									:total-user-count="card.interested_leaders.length"
									size="small"
									variant="primary"
									:max="3"
									class="avatar-list"
								>
								</cs-avatar-list>
								<div class="interest-text">
									{{ card.interested_leaders.length }}
									{{ leadersForm }} interested
								</div>
							</div> -->
							<div class="actions">
								<!-- Disabled until V2 -->
								<!-- <i class="cs-icons-share" @click="share(card)"></i> -->
								<i
									:class="[{ active: commented }, filledComment]"
									@click="showComments"
								></i>
							</div>
						</div>
						<div v-if="config && config.card_isPrivate" class="header private">
							<i class="cs-icons-lock"></i>
							<div class="cs-textstyle-paragraph-bold">Answers are private</div>
						</div>

						<slot name="card-content"></slot>
					</div>
				</cs-card>
			</div>
		</div>
	</div>
</template>

<script>
import { shareCard } from '@/services/share';

import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import $bus from '@/services/bus';

import { itemConfig } from '@/services/cardConfig';

export default {
	components: {},
	props: {
		card: {
			type: Object,
		},
	},
	data() {
		return {
			commented: false,
			bookmarked: false,
		};
	},
	computed: {
		config() {
			if (!this.card) return null;
			const typename = this.card.__typename || this.card.typename;
			if (!typename) return null;
			return itemConfig[typename];
		},
		// leadersForm() {
		// 	if (this.card.interested_leaders.length === 1) {
		// 		return 'leader is';
		// 	}
		// 	return 'leaders are';
		// },
		// userList() {
		// 	const members = this.card.interested_leaders.map((person) => ({
		// 		name: `${person.first_name}+ ' ' + ${person.last_name}`,
		// 		picture: person.photo_url,
		// 	}));
		// 	return members;
		// },
		filledBookmark() {
			if (this.bookmarked) {
				return 'cs-icons-bookmark-filled';
			} else {
				return 'cs-icons-bookmark';
			}
		},
		filledComment() {
			if (this.commented) {
				return 'cs-icons-comment-filled';
			} else {
				return 'cs-icons-comment';
			}
		},
		...mapGetters('auth', ['userHasCommentedItem', 'userCommentedItemIds']),
	},
	beforeMount() {
		if (this.userHasCommentedItem(this.card.id)) {
			this.commented = true;
		}
	},
	mounted() {
		$bus.$on('commented', () => {
			if (this.userHasCommentedItem(this.card.id)) {
				this.commented = true;
			}
		});
		$bus.$on('removedComment', () => {
			if (!this.userHasCommentedItem(this.card.id)) {
				this.commented = false;
			}
		});
	},
	methods: {
		...mapMutations(['setUserCommentedItems']),
		async share(card) {
			shareCard(card);
		},
		// showInterestedPeople() {
		// 	$bus.$emit('open-interested-people-modal', this.card);
		// },
		showComments() {
			$bus.$emit('open-comments-modal', this.card);
		},
	},
};
</script>

<style scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--cs-gray-02);
	padding-bottom: 6px;
	flex-flow: row;
}
.header i {
	font-size: 22px;
	padding: 10px;
}
.see-less {
	text-align: center;
	padding: 16px;
	font-weight: 400;
	color: var(--cs-gray-03);
	cursor: pointer;
}
.actions {
	margin-left: auto;
}
.header.private {
	flex-flow: row;
	justify-content: flex-start;
	background: var(--cs-primary-base);
	color: white;
	margin: calc(0px - var(--cs-card-padding-y))
		calc(0px - var(--cs-card-padding-x));
	margin-bottom: var(--cs-card-padding-y);
	padding-bottom: 0;
}
.header.private i {
	padding: 4px 8px 4px var(--cs-card-padding-x);
}
.interests {
	display: flex;
	flex-direction: row;
}
.interest-text {
	padding: 0px 0px 0px 5px;
}
.avatar-list {
	--cs-avatar-list-size: 18px;
	--cs-avatar-list-font-size: 10px;
	padding-top: 1px;
}
.active {
	color: var(--cs-primary-base);
}
</style>
