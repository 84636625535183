<template>
  <div>
    <app-loader v-if="loading" />
    <div class="others-profile-page" v-if="person">
      <secondary-header
        allowBack
        :title="person.first_name + ' ' + person.last_name"
      ></secondary-header>
      <div class="profile-information">
        <app-profile
          :name="person.first_name + ' ' + person.last_name"
          :picture="person.photo_url"
          :location="person.current_city"
          :detail="person.occupation"
        >
        </app-profile>
        <div class="request-intro-button">
          <cs-button corners="rounded" @click="openMeetModal"
            >Request Intro</cs-button
          >
        </div>
      </div>
      <div class="profile-information">
        <div class="section" v-if="person.summary">
          <div class="cs-textstyle-informative-paragraph-bold section-title">
            About
          </div>
          <read-more
            v-if="person.summary"
            :text="person.summary"
            more-text="...Read More"
            less-text="Read Less"
            :limit="150"
          ></read-more>
        </div>
        <div class="section" v-if="person.interests && person.interests.length">
          <div class="cs-textstyle-informative-paragraph-bold section-title">
            Interests
          </div>
          <tag-list
            v-if="person.interests && person.interests.length"
            :tags="person.interests.map((e) => e.interest)"
            :forceOneRow="false"
          ></tag-list>
        </div>
      </div>
    </div>
    <primary-action-modal
      :show="showMeetModal"
      :config="currentConfig"
      :item="person"
      @close="closeMeetModal"
    ></primary-action-modal>
  </div>
</template>

<script>
import getPersonById from "@/graphql/people/getPersonById.gql";
import SecondaryHeader from "@/components/general/SecondaryHeader.vue";
import ReadMore from "@/components/general/ReadMore.vue";
import TagList from "@/components/general/TagList.vue";
import AppLoader from "@/components/general/Loader.vue";
import PrimaryActionModal from "../components/modals/PrimaryActionModal.vue";
import AppProfile from "@/components/general/AppProfile.vue";

export default {
  components: {
    SecondaryHeader,
    TagList,
    ReadMore,
    PrimaryActionModal,
    AppLoader,
    AppProfile,
  },
  apollo: {
    person: {
      query: getPersonById,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
  data() {
    return {
      showMeetModal: false,
      currentConfig: {
        card_componentName: "PersonCard",
        card_icon: "cs-icons-profile",
        card_primaryButtonLabel: "Meet ",
        card_title: "Connection",
        modal_componentName: "MeetRequestModalContent",
        modal_title: "Meet",
      },
    };
  },
  computed: {
    loading() {
      return this.$apollo.queries.person.loading;
    },
  },
  methods: {
    openMeetModal() {
      this.showMeetModal = true;
    },
    closeMeetModal() {
      this.showMeetModal = false;
    },
  },
};
</script>

<style scoped>
.others-profile-page {
  margin-top: calc(-16px + env(safe-area-inset-top));
  padding-bottom: 16px;
}
.section {
  margin-bottom: 16px;
}
.section p {
  margin-bottom: 0px;
}
.section .section-title {
  margin-bottom: 8px;
  color: var(--cs-gray-06);
}
.profile-information {
  background: var(--cs-gray-00);
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px 0px #0000000d;
  margin-bottom: 17px;
}
::v-deep .cs-profile__main {
  padding: 0px;
}
.tags-list {
  margin: 0px;
}
.cs-tag {
  margin-bottom: 0px;
}
.section:last-child {
  margin-bottom: 0px;
}
.text {
  margin-top: 0px;
}
.tags-list:not(.force-one-row) >>> * {
  margin-bottom: 0px;
}
.request-intro-button {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}
</style>
