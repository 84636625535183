import { getApolloClient } from '@/vue-apollo';
import setPersonGoal from '@/graphql/goals/setPersonGoal.gql';
import setGoalAnswer from '@/graphql/goals/setGoalAnswer.gql';
import { analytics } from '@/services/utils.js'

import $bus from '@/services/bus';

import $dialog from '@/services/dialog';

import { store as $store } from '@/store';

const goalConfig = {
  card_componentName: 'GoalCard',
  card_title: 'Goal',
  card_icon: 'cs-icons-trophy',
  card_primaryButtonLabel: 'Submit',
  card_isPrivate: true,
  disablePrimaryUnlessInput: true,
  card_removeCardResponseOnToggle: true,

  modal_componentName: 'GoalModalContent',
  modal_title: 'Questions',
  gtag_label: 'goal-card',

  primaryCardAction: async (card) => {
    // if the user answers "no", we don't save the goal and we move to the next card

    if (card && !card.values && card.cardInput.toLowerCase() === 'true') {
      // if there is no user data but we have the input as "true",
      // it is possible that the user has exited the modal
      // and we should open back the modal
      $bus.$emit('open-primary-action-modal', card);
      return;
    }

    //store answers to the follow-up questions
    $store.dispatch('cards/setCardUserResponse', {
      card,
      userResponse: card.values && card.values.length ? card.values : null,
    });

    if (card && card.cardInput && card.cardInput.toLowerCase() === 'false') {
      $bus.$emit('next-card');
      return;
    }

    if (card && card.values) {
      // save answers for the goal questions
      let numberOfRequests = 0;
      for (let question in card.values) {
        getApolloClient()
          .mutate({
            mutation: setGoalAnswer,
            variables: {
              answerId: card.values[question].value,
            },
          })
          .then((data) => {
            console.log(data);
            //TODO: delete console logs and handle the mutations' completions better after fixes in backend
            numberOfRequests += 1;
            if (numberOfRequests === Object.keys(card.values).length) {
              console.log('All requests completed successfully.');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      $dialog.alert(
        'There has been an issue saving your goal. Please try again later.'
      );
    }
  },

  onInput: async (value, card) => {
    if (value.toLowerCase() === 'true') {
      card.cardInput = 'true';
      $bus.$emit('open-primary-action-modal', card);
    } else {
      card.cardInput = 'false';
      $bus.$emit('next-card');
    }
    setPersonalGoal(card);
  },

  getCardActionLabel: (card) => {
    const hasAnsweredGoal = $store.getters['cards/getCardInput'](card.id);
    if (hasAnsweredGoal && hasAnsweredGoal.length)
      return { label: 'Change answer', changed: true };
    return { label: 'Submit', changed: false };
  },

  triggerChangeAnswer: ()=>{
    analytics({eventName: 'goal_change-answer-click', route: 'card-deck'})
  }
};

async function setPersonalGoal(card) {
  const { data } = await getApolloClient()
    .mutate({
      mutation: setPersonGoal,
      variables: {
        goalId: card.id,
        hasGoal: card.cardInput.toLowerCase() === 'true',
      },
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export default goalConfig;
