<template>
  <div class="request-invite page">
    <auth-layout>
      <img slot="image" src="../assets/img/LO_logo_gray.svg" />
      <div slot="text">
        <h1 class="cs-textstyle-page-title">Request Invitation</h1>
        <p class="cs-textstyle-paragraph">
          Leaders.org an invitation-only network of influential leaders who help
          each other succeed. If you feel you're a good fit, complete this form
          to request an invite.
        </p>
      </div>
      <div slot="fields" class="request-invite__fields">
        <cs-input
          v-model="name"
          placeholder="Enter name"
          :required="true"
        ></cs-input>
        <cs-input
          v-model="email"
          type="email"
          placeholder="Enter email"
          :required="true"
        ></cs-input>
        <cs-input
          v-model="linkedin"
          placeholder="Linkedin profile"
          :required="true"
        ></cs-input>
      </div>
      <div
        slot="buttons-info"
        class="cs-textstyle-paragraph-small request-invite__info"
      >
        Have an invitation code?
        <router-link to="signup">Join Here</router-link>
      </div>
      <cs-button
        slot="buttons"
        corners="rounded"
        @click="requestInvite"
        block
        :disabled="valid"
        >Request Invitation</cs-button
      >
    </auth-layout>
  </div>
</template>

<script>
import AuthLayout from '../components/layouts/AuthLayout.vue';
import requestInvitation from '@/graphql/people/requestInvitation.gql';

import $dialog from '@/services/dialog';
import { analytics } from '@/services/utils';

export default {
  components: { AuthLayout },
  mounted() {
    analytics({eventName: 'request-invitation', route: 'splash'})
  },
  data() {
    return {
      name: '',
      email: '',
      linkedin: '',
    };
  },
  computed: {
    valid() {
      return !(this.name.length && this.email.length && this.linkedin.length);
    },
  },
  methods: {
    async requestInvite() {
      const { data } = await this.$apollo
        .mutate({
          mutation: requestInvitation,
          variables: {
            linkedin_url: this.linkedin,
            email: this.email,
            name: this.name,
          },
        })
        .catch((error) => {
          console.log(error);
        });
      if (data && data.requestInvitation) {
        $dialog.alert(
          'Thank you for your interest! We will analyse your request and get back to you soon.'
        );
      } else {
        $dialog.alert('Something went wrong. Please try again later.');
      }
    },
  },
};
</script>

<style scoped>
.splash {
  height: 100%;
}
.request-invite {
  margin-top: -16px;
}
.request-invite__fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.request-invite__info {
  text-align: center;
  margin-bottom: 12px;
}
</style>
