<template>
  <div class="swiper-slide">
    <slot/>
  </div>
</template>
<script>
import 'swiper/swiper.min.css';

export default {

}
</script>

<style scoped>
.swiper-slide {
	height: auto;
  ---main-header-height: calc(30px + 16px + 16px + 8px); 
  ---main-footer-height: calc(40px + 16px + 16px); 
  padding-top: calc(env(safe-area-inset-top) + var(---main-header-height)); 
  padding-bottom: calc( env(safe-area-inset-bottom) + var(---main-footer-height) ); 
  overflow-x: hidden; 
  overflow-y: scroll;
}
</style>