<template>
	<div class="lo-infinite-simple">
		<app-infinite-loader
			:paused="query.loading"
			:finished="!mayHaveMore"
			class="lo-infinite-loader"
			@more="loadMore()"
		></app-infinite-loader>
		<cs-empty-state
			v-if="!query.loading && finished"
			class="lo-infinite-simple__end-results"
			description="End of results"
		/>
	</div>
</template>

<script>
import AppInfiniteLoader from '@/components/general/InfiniteLoader.vue';

export default {
	components: {
		AppInfiniteLoader,
	},
	props: {
		query: {
			type: Object,
			required: true,
		},
		sortKey: {
			type: String,
			required: true,
		},
	},
	computed: {
		lastVariables() {
			return (
				(this.query &&
					this.query.lastApolloOptions &&
					this.query.lastApolloOptions.variables) ||
				{}
			);
		},
	},
	data() {
		return {
			mayHaveMore: true,
			finished: false,
		};
	},
	methods: {
		loadMore() {
			if (this.mayHaveMore) {
				this.mayHaveMore = false;

				const items = this.query.vm[this.query.key];
				if (items.length === 0) {
					this.mayHaveMore = false;
					return;
				}
				const defaultLimit = 20;
				const variables = { limit: defaultLimit, ...this.lastVariables };
				variables[this.sortKey] = items[items.length - 1][this.sortKey];

				const key = this.query.key;
				this.query
					.fetchMore({
						variables,
						updateQuery: (previousResult, { fetchMoreResult }) => {
							setTimeout(() => {
								this.mayHaveMore = !!fetchMoreResult[key].length;
								if (!this.mayHaveMore) this.finished = true;
							}, 1000);
							const list = previousResult[key].slice();
							const incoming = fetchMoreResult[key];
							const nextBatch = incoming.filter(({ id }) =>
								list.every((entry) => entry.id !== id)
							);
							const result = [...list, ...nextBatch];
							return { [key]: result };
						},
					})
					.catch((err) => {
						console.error('error loading more', err);
					});
			}
		},
	},
};
</script>

<style scoped>
.lo-infinite-simple {
	min-height: 200px;
}
.lo-infinite-simple__end-results {
	color: var(--cs-gray-05);
}
</style>
