<template>
  <div>
    <cs-profile
      :picture="picture"
      :name="name"
      :detail="detail"
      :company-logo="companyLogo"
      :location="location"
      :avatar-position="avatarPosition"
      :avatar-size="avatarSize"
      :no-avatar="noAvatar"
      :href="href"
      :to="to"
    >
    </cs-profile>
  </div>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: null,
    },
    to: {
      type: [Object, String],
      default: null,
    },
    picture: {
      type: String,
      default: null,
    },
    companyLogo: {
      type: String,
      default: null,
    },
    location: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    detail: {
      type: String,
      default: null,
    },
    avatarPosition: {
      type: String,
      default: "center",
    },
    avatarSize: {
      type: String,
      default: "small",
    },
    noAvatar: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
::v-deep .cs-avatar {
  --cs-avatar-size: 120px;
}
::v-deep .cs-profile .cs-profile__content > div,
::v-deep .cs-profile .cs-profile__content > div > i {
  font-size: 16px;
  color: var(--cs-gray-06);
  font-weight: 700;
}
::v-deep .cs-profile .cs-profile__content .cs-profile__content-name {
  font-size: 20px;
}
</style>
