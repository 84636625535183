<template>
  <div>
    <app-saved-card
      v-for="card in cards"
      :key="card.id"
      :card="card"
      @click="viewCard(card)"
    />
    <app-card-modal
      :show="show"
      @close="closeModal"
      :item-id="selectedCardId"
      :item-type="selectedCardType"
    />
  </div>
</template>

<script>
import AppSavedCard from '@/components/cards/SavedCard.vue';
import AppCardModal from '@/components/modals/CardModal.vue';
import { itemConfig } from '@/services/cardConfig';
import { analytics } from '@/services/utils';

export default {
  components: {
    AppSavedCard,
    AppCardModal,
  },
  props: {
    cards: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      selectedCardId: null,
      selectedCardType: null,
    };
  },
  methods: {
    viewCard(card) {
      this.selectedCardId = card.id;
      this.selectedCardType = card.typename;
      this.show = true;
      analytics({eventName: 'card_card-click', label: itemConfig[this.selectedCardType].gtag_label})
    },
    closeModal() {
      analytics({eventName: 'modal_close-button-click', label: itemConfig[this.selectedCardType].gtag_label})
      this.show = false;
      this.selectedCardId = null;
      this.selectedCardType = null;
    },
  },
};
</script>

<style scoped></style>
