<template>
  <app-modal :show="show" @close="close" title="Comments">
    <div>
      <app-loader v-if="loading" class="loader" />
      <cs-comment
        v-for="(comment, index) in sortedComments"
        :key="index"
        :author="{
          name: `${name(comment.author)}`,
          picture: comment.author.photo_url,
        }"
        :message="comment.message"
        :date="comment.updated_at"
        :action-buttons="[]"
        :show-menu="isAuthorOfComment(comment)"
        @open-menu="openMenuForCommentId = comment.id"
        @author="goToUserProfile(comment.author)"
      >
      </cs-comment>

      <cs-message-box
        v-model="newComment"
        placeholder="Share your thoughts..."
        @send="submitComment"
      ></cs-message-box>

      <action-sheet
        :open="!!openMenuForCommentId.length"
        @close="openMenuForCommentId = ''"
      >
        <cs-button
          class="action-sheet-button"
          variant="danger"
          fill="clear"
          icon="cs-icons-delete"
          block
          @click="deleteComment"
        >
          <span>Delete comment</span>
        </cs-button>
      </action-sheet>
    </div>
    <empty-state
      v-if="comments && !comments.length"
      size="medium"
      title="No Comments Yet"
      :description="`No previous comments for this ${itemType.toLowerCase()}. Be the first one to comment.`"
      imageName="NoCommentsPlaceholder.png"
    />
  </app-modal>
</template>

<script>
import listItemComments from '../../graphql/comments/listItemComments.gql';
import insertComment from '../../graphql/comments/insertComment.gql';
import deleteComment from '../../graphql/comments/deleteComment.gql';
import ActionSheet from '../general/ActionSheet.vue';
import AppModal from '@/components/modals/Modal.vue';
import EmptyState from '@/components/general/EmptyState.vue';
import AppLoader from '@/components/general/Loader.vue';

import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import $bus from '@/services/bus';
import { itemConfig } from '@/services/cardConfig';
import { analytics } from '@/services/utils';

export default {
  components: { ActionSheet, AppModal, EmptyState, AppLoader },
  props: {
    itemId: {
      type: String,
    },
    show: {
      type: Boolean,
    },
    itemType: {
      type: String,
    },
  },
  apollo: {
    comments: {
      query: listItemComments,
      variables() {
        return {
          itemId: this.itemId,
        };
      },
    },
  },
  data() {
    return {
      newComment: ' ',
      openMenuForCommentId: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['userCommentedItemIds', 'userId']),
    loading() {
      return this.$apollo.queries.comments.loading;
    },
    sortedComments() {
      return this.sortComments(this.comments);
    },
  },
  methods: {
    ...mapMutations(['setUserCommentedItems']),
    name(author) {
      return author.first_name + ' ' + author.last_name;
    },
    close() {
      this.$emit('close');
    },
    sortComments(comments) {
      return (
        comments &&
        comments.sort(
          (a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at)
        )
      );
    },
    isAuthorOfComment(comment) {
      return comment.author.id === this.userId;
    },
    async submitComment(value) {
      const event_label = itemConfig[this.itemType].gtag_label;
      analytics({eventName: 'comment_leave-comment',label: event_label})
      if (!this.newComment.length) return;

      let { data } = await this.$apollo
        .mutate({
          mutation: insertComment,
          variables: {
            message: value,
            itemId: this.itemId,
          },
        })
        .catch((error) => {
          console.log(error);
        });
      if (data) {
        let id = data.insert_comment.id;
        let item_id = this.itemId;
        this.$store.commit('auth/setComment', { id, item_id });
        $bus.$emit('commented');
        this.$apollo.queries.comments.refetch();
      }
    },
    async deleteComment() {
      const event_label = itemConfig[this.itemType].gtag_label;
      analytics({eventName:'comment_delete-comment', label: event_label})
      let { data } = await this.$apollo
        .mutate({
          mutation: deleteComment,
          variables: {
            commentId: this.openMenuForCommentId,
          },
        })
        .catch((error) => {
          console.log(error);
        });
      if (data) {
        let item_id = this.itemId;
        this.$store.commit('auth/removeCommentedItem', { item_id });
        $bus.$emit('removedComment');
        this.openMenuForCommentId = '';
        this.$apollo.queries.comments.refetch();
      }
    },
    goToUserProfile(author) {
      analytics({eventName: 'comment_profile-visit'})
      if (this.userId === author.id) {
        this.$router.push('profile');
      } else {
        this.$router.push({ name: 'OthersProfile', params: { id: author.id } });
      }
      this.close();
    },
  },
};
</script>

<style scoped>
.message-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.action-sheet-button {
  --cs-button-padding: 16px 0px;
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
</style>
