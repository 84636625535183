<template>
  <cs-card class="answers-summary" v-if="answers.length">
    <div slot="body">
      <div v-for="(question, index) in answers" :key="index" class="answer-item">
        <span class="question-number cs-textstyle-paragraph-extra-small-bold">{{index + 1}}</span>
        <div class="answers" v-if="answers[index]" >
          <div class="title cs-textstyle-paragraph-bold">{{answers[index].questionTitle}}</div>
          <div class="title cs-textstyle-paragraph">{{answers[index].answerTitle}}</div>
        </div>
      </div>
    </div>
  </cs-card>
</template>

<script>
export default {
  props: {
    answers: {
      type: Array
    }
  }
}
</script>

<style scoped>
.answers-summary.cs-card {
  --cs-card-background: var(--cs-primary-lightest);
  --cs-card-shadow: none;
   border: 0;
  margin-bottom: var(--cs-card-content-margin-y);
}
.answers-summary.cs-card .answer-item {
  display: flex;
  align-items: baseline;
}
.answers-summary.cs-card .answer-item:not(:last-child) {
  margin-bottom: var(--cs-card-content-margin-y);
}
.answers-summary.cs-card .question-number {
  border-radius: 50%;
  background: var(--cs-primary-base);
  min-width: 18px;
  min-height: 18px;
  box-sizing: border-box;
  color: #ffffff;    
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.answers-summary.cs-card .answers {
  color: var(--cs-primary-base);
}
</style>