<template>
  <div class="event-card">
    <tag-list v-if="card.tags && card.tags.length" :tags="card.tags" :forceOneRow="true"></tag-list>
    <div class="title cs-textstyle-item-heading">{{card.title}}</div>
    <div class="section">
      <div class="date-time flexH cs-textstyle-paragraph-small">
        <i class="cs-icons-calendar"></i>
        <div>
          <div class="days cs-textstyle-paragraph-small-bold">{{date}}</div>
          <div class="time">{{time}}</div>
        </div>
      </div>
      <div class="location flexH cs-textstyle-paragraph" v-if="!card.is_online">
        <i class="cs-icons-marker"></i>
        {{card.location}}, {{card.city.name}}, {{card.city.country}}
      </div>
      <div class="online flexH cs-textstyle-paragraph" v-if="card.is_online">
        <i class="cs-icons-live"></i>
        Online
      </div>
      <div class="tickets flexH cs-textstyle-paragraph">
        <i class="cs-icons-ticket"></i>
        {{`${card.price} ${card.currency}` || 'Free'}}
      </div>
    </div>
    <div class="section">
        <read-more
          v-if="card.description"
          :text="card.description"
          more-text="...Read More"
          less-text="Read Less"
          :limit="150"
        ></read-more>
    </div>
  </div>
</template>

<script>
import ReadMore from '../general/ReadMore.vue';
import TagList from "../general/TagList.vue";
import { formatDateInterval, formatTimeInterval } from "../../services/utils";

export default {
  components: {
    ReadMore,
    TagList,
  },
  computed: {
    date() {
      return formatDateInterval(this.card.starts_at, this.card.ends_at);
    },
    time() {
      return formatTimeInterval(this.card.starts_at, this.card.ends_at);
    }
  },
  props: {
    card: {
      type: Object
    }
  },
  methods: {
  }, 
}
</script>

<style scoped>
.section {
  margin-bottom: 16px;
  margin-top: 16px;
}
.section .section-title{
  margin-bottom: 8px;
}
.title, .section-title {
  color: var(--cs-gray-06);
}
.tags > *{ 
  margin-right: 8px;
}
.cs-tag {
  --cs-tag-color: var(--cs-gray-02);
  --cs-tag-text-color: var(--cs-gray-05);
}
::v-deep .cs-tag > * {
  font-weight: 600;
}
.section.tags {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.flexH {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 12px;

}
i {
  font-size: 16px;
  margin-right: 8px;
}
</style>