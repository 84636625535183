<template>
  <div class="multiple-answers question">
    <cs-checkbox-group 
      v-if="question.choiceType == 'multiple'"
      :label="question.title"
    >
      <cs-checkbox
        v-for="(item, i) in question.options"
        :key="i"
        @input="toggleSelect(item)"
        ><span class="checkbox-value">{{ item }}</span>
      </cs-checkbox>
    </cs-checkbox-group>

    <cs-textarea
      v-if="textEnable"
      no-label
      v-model="question.otherAnswer"
      :placeholder="question.textareaPlaceholder"
    />
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object
    }
  },
  data() {
    return {
      textEnable: false,
    }
  },
  methods: {
    toggleSelect($event) {
      if(this.question.value.length) {
        const index = this.question.value.indexOf($event);
        if(index > -1) {
          this.question.value.splice(index, 1);
        } else {
          this.question.value.push($event);
        }
      } else {
        this.question.value.push($event);
      }
      this.textEnable = this.question.value.length && this.question.value.indexOf('Other') > -1;
      this.$emit('input', this.question.value);
    },
  },
}
</script>

<style scoped>
.multiple-answers.question >>> .cs-checkbox-group__label {
  color: var(--cs-gray-06);
}
.multiple-answers.question >>> .cs-checkbox {
  margin: 24px 0;
}
</style>