<template>
  <i class="lo-save-card-button" :class="icon" @click.stop="toggleFollow" />
</template>
<script>
import { mapGetters } from 'vuex';
import $toast from '@/services/toast';
import { analytics } from '@/services/utils';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    warnUnsave: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('auth', ['userHasSavedItem']),
    isSaved() {
      return this.userHasSavedItem(this.item.id);
    },
    icon() {
      return this.isSaved ? 'cs-icons-bookmark-filled' : 'cs-icons-bookmark';
    },
  },
  methods: {
    toggleFollow() {
      this.isSaved ? this.unsaveCard() : this.saveCard();
    },
    saveCard() {
      analytics({eventName: 'card_save-card', label: this.config.gtag_label})
      this.$store.dispatch('auth/saveItem', this.item);
    },
    async unsaveCard() {
      analytics({eventName: 'card_unsave-card', label: this.config.gtag_label})
      this.$store.dispatch('auth/unsaveItem', this.item);
      if (this.warnUnsave) {
        const response = await $toast.show({
          message: 'Removed from saved cards',
          button: 'Undo',
        });
        if (response && response === 'Undo') this.saveCard();
      }
    },
  },
};
</script>
<style scoped>
.lo-save-card-button.cs-icons-bookmark-filled {
  color: var(--cs-primary-base);
}
</style>
