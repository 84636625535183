<template>
  <div class="single-answers question">
    <cs-radio-group 
      v-if="question.choiceType == 'single'"
      :label="question.title"
      :options="question.options"
      v-model="question.value"
      class="radio-group"
      @input="$emit('input', question.value)"
      >
    </cs-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object
    }
  }
}
</script>
<style scoped>
.single-answers.question >>> .cs-radio-group__label {
  color: var(--cs-gray-06);
}
.single-answers.question >>> .cs-radio {
  margin: 16px 0 0 0;
}
</style>
