import setEventResponse from '@/graphql/events/setEventResponse.gql';
import $dialog from '@/services/dialog';
import $bus from '@/services/bus';

import { getApolloClient } from '@/vue-apollo';
import getEvent from '@/graphql/events/getEvent.gql';

import { store as $store } from '@/store';

const eventConfig = {
  card_componentName: 'EventCard',
  card_title: 'Event',
  card_icon: 'cs-icons-calendar',
  card_hasMediaSlot: true,
  card_item_query: getEvent,
  card_item_query_key: 'event',
  gtag_label: 'event-card',

  share_props: {
    title: 'title',
    message: 'description',
  },

  modal_componentName: 'EventModalContent',
  modal_title: 'Register to Event',

  getCardActionLabel: (card) => {
    const isAttending = $store.getters['auth/userIsAttendingEvent'](card.id);
    if (isAttending) return { label: "You're going", changed: true };
    return { label: 'Register', changed: false };
  },

  primaryCardAction: async (card) => {
    const result = await getApolloClient()
      .mutate({
        mutation: setEventResponse,
        variables: {
          status: 'attending',
          eventId: card.id,
        },
      })
      .catch((error) => {
        console.log(error);
      });

    if (result) {
      $bus.$emit('open-primary-action-modal', card);
    } else {
      $dialog.alert(
        'There was an error executing the last action. Please try again.'
      );
    }
  },
};

export default eventConfig;
