<template>
  <div v-if="comments.length > 0">
    <div v-for="item in comments" :key="item.id">
      <cs-comment
        :message="item.message"
        :key="item.id"
        class="comment-box"
        :date="item.updated_at"
        :actionButtons="[]"
        :author-picture="item.author.photo_url"
        :author-name="name(item.author)"
        @open-menu="openMenu(item)"
        :show-menu="isAuthorOfComment(item)"
      >
      </cs-comment>
    </div>
    <action-sheet
      :open="!!openMenuForCommentId.length"
      @close="openMenuForCommentId = ''"
    >
      <cs-button
        class="action-sheet-button"
        variant="danger"
        fill="clear"
        v-if="authorId === this.user.id"
        icon="cs-icons-delete"
        block
        @click="deleteComment"
      >
        <span>Delete comment</span>
      </cs-button>
      <div v-else class="text-center cs-textstyle-paragraph">
        You cannot delete this comment
      </div>
    </action-sheet>
  </div>
</template>

<script>
import ActionSheet from './ActionSheet.vue';
import deleteComment from '../../graphql/comments/deleteComment.gql';

import { mapState } from 'vuex';

export default {
  components: { ActionSheet },
  props: {
    comments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      comment: '',
      openMenuForCommentId: '',
      authorId: null,
    };
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
    }),
  },
  methods: {
    isAuthorOfComment(comment) {
      return comment.author.id === this.user.id;
    },
    name(author) {
      return author.first_name + ' ' + author.last_name;
    },
    openMenu(item) {
      this.openMenuForCommentId = item.id;
      this.authorId = item.author_id;
    },
    async deleteComment() {
      let { data } = await this.$apollo
        .mutate({
          mutation: deleteComment,
          variables: {
            commentId: this.openMenuForCommentId,
          },
        })
        .catch((error) => {
          console.log(error);
        });
      if (data) {
        this.openMenuForCommentId = '';
        this.$emit('update');
      }
    },
  },
};
</script>

<style scoped>
.comment-box.cs-comment {
  ---cs-comment-background-color: var(--cs-gray-01);
}
.action-sheet-button {
  --cs-button-padding: 16px 0px;
}
.text-center {
  text-align: center;
}
</style>
