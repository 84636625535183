<template>
	<div class="goal-modal-content">
		<answer-summary :answers="previousAnswers"></answer-summary>
		<cs-card>
			<div class="questions" v-if="item.questions.length" slot="body">
				<app-add-progress-bar
					:step="step + 1"
					:totalStep="item.questions.length"
				/>
				<div class="question-box">
					<single-answers :question="currentQuestion" @input="checkValue">
					</single-answers>
				</div>
				<cs-button
					corners="rounded"
					block
					:disabled="isDisabled"
					@click="handleNext"
					>{{ buttonLabel }}</cs-button
				>
			</div>
		</cs-card>
	</div>
</template>

<script>
import AppAddProgressBar from '@/components/general/AddProgressBar.vue';
import SingleAnswers from '@/components/questions/SingleAnswers.vue';
import AnswerSummary from '@/components/questions/AnswerSummary.vue';

import goalConfig from '@/services/cardConfig/goal';
import { invokePrimaryAction } from '@/services/api';
import { analytics } from '@/services/utils';

export default {
	data() {
		return {
			step: 0,
			isDisabled: true,
			values: {},
		};
	},
	components: { AppAddProgressBar, SingleAnswers, AnswerSummary },
	props: {
		item: {
			type: Object,
		},
	},
	computed: {
		currentQuestion() {
			return {
				id: this.item.questions[this.step].id,
				title: this.item.questions[this.step].title,
				choiceType: 'single',
				options: this.item.questions[this.step].options.map((o) => {
					return {
						label: o.title,
						value: o.id,
					};
				}),
				value: null,
			};
		},
		buttonLabel() {
			return this.step + 1 < this.item.questions.length ? 'Continue' : 'Submit';
		},
		previousAnswers() {
			return [...Object.values(this.values)];
		},
	},
	methods: {
		async handleNext() {
			if (this.step + 1 < this.item.questions.length) {
				this.step += 1;
				this.isDisabled = true;
				this.updatePreviousAnswers();
			} else {
				analytics({eventName: 'goal_answer-followup-questions'})
				this.submitGoalAnswers();
				this.$emit('close', [...Object.values(this.values)]);
			}
		},
		checkValue(value) {
			this.isDisabled = !value || !value.length;
			const [optionConfig] = this.currentQuestion.options.filter(
				(option) => option.value === value
			);
			this.values[this.currentQuestion.id] = {
				questionTitle: this.currentQuestion.title,
				answerTitle: optionConfig.label,
				value: optionConfig.value,
			};
		},
		updatePreviousAnswers() {
			//this is needed to trigger the re-render for the previousAnswers computed prop
			this.values = { ...this.values };
		},
		submitGoalAnswers() {
			const item = JSON.parse(JSON.stringify(this.item));
			item.values = [...Object.values(this.values)];
			item.cardInput = 'true';
			goalConfig.primaryCardAction(item);
			invokePrimaryAction(this.$apollo, item);
		},
	},
};
</script>

<style scoped>
.goal-modal-content .questions .cs-button {
	margin-top: 24px;
}
</style>
