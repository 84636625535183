import getItemResponseQuery from "@/graphql/item/getItemResponse.gql";
import setItemInterestQuery from "@/graphql/item/setItemInterest.gql";
import setItemViewedQuery from "@/graphql/item/setItemViewed.gql";
import setItemNavigatedBackQuery from "@/graphql/item/setItemNavigatedBack.gql";
import setItemInvokePrimaryAction from "@/graphql/item/setItemInvokedPrimaryAction.gql";

export const getItemResponse = async function ($apollo, itemId) {
  const { data } = await $apollo
    .query({
      query: getItemResponseQuery,
      variables: {
        itemId: itemId,
      },
    })
    .catch((error) => {
      console.log(error);
    });
  return data.itemResponse;
};

export const updateItemInterestScore = async function ($apollo, itemResponse) {
  const durationScore =
    itemResponse.viewed_duration < 5000
      ? 0
      : itemResponse.viewed_duration >= 5000 &&
        itemResponse.viewed_duration < 15000
      ? 0.5
      : 1.0;

  const interestScore = Math.round(
    (1 - itemResponse.rejected) *
      (0.2 * itemResponse.saved +
        0.25 * itemResponse.commented +
        0.2 * durationScore +
        0.3 * itemResponse.invoked_primary_action +
        0.05 * itemResponse.navigated_back) *
      100
  );

  console.log(
    "The current user's interest for this card, between 0 and 100, is: ",
    interestScore
  );

  let { data } = await $apollo
    .mutate({
      mutation: setItemInterestQuery,
      variables: {
        itemId: itemResponse.item_id,
        interestScore: interestScore,
      },
    })
    .catch((e) => {
      console.log(e);
    });

  if (data) {
    console.log(data);
  }
};

export const setItemViewed = async function (
  $apollo,
  itemId,
  position,
  duration
) {
  const { data } = await $apollo
    .mutate({
      mutation: setItemViewedQuery,
      variables: {
        itemId: itemId,
        viewedPosition: position,
        viewedDuration: duration,
      },
    })
    .catch((error) => {
      console.log(error);
    });

  if (data) {
    updateItemInterestScore($apollo, data.itemResponse);
  }
};

export const setItemNavigatedBack = async function (
  $apollo,
  itemId,
  navigatedBack
) {
  const { data } = await $apollo
    .mutate({
      mutation: setItemNavigatedBackQuery,
      variables: {
        itemId: itemId,
        navigatedBack: navigatedBack,
      },
    })
    .catch((error) => {
      console.log(error);
    });

  if (data) {
    console.log(data);
  }
};

export const invokePrimaryAction = async function ($apollo, item) {
  const { data } = await $apollo
    .mutate({
      mutation: setItemInvokePrimaryAction,
      variables: {
        invokedPrimaryAction: true,
        itemId: item.id,
      },
    })
    .catch((error) => {
      console.log(error);
    });

  if (data) {
    console.log("invoked primary action");
  }
};
