<template>
	<div class="empty_state">
		<img
			v-if="imageName"
			:class="['empty_state-img--' + size]"
			:src="require(`@/assets/img/${imageName}`)"
		/>
		<cs-empty-state :title="title" :description="description" />
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
		},
		description: {
			type: String,
		},
		imageName: {
			type: String,
		},
		size: {
			type: String,
			default: 'medium',
		},
	},
};
</script>

<style scoped>
.empty_state {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 60px;
	padding-bottom: 40px;
}
.empty_state-img--small {
	width: auto;
	height: 100px;
}
.empty_state-img--medium {
	width: auto;
	height: 150px;
}
.empty_state-img--large {
	width: auto;
	height: 200px;
}
</style>
