<template>
  <div
    :class="['tags-list', { 'force-one-row': forceOneRow }]"
    v-if="tags && tags.length"
  >
    <cs-tag v-for="(tag, index) in tags" :key="index">{{ tag }}</cs-tag>
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
    },
    forceOneRow: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.tags-list {
  margin: var(--cs-card-content-margin-y) 0px;
}
.tags-list > * {
  margin-right: 8px;
}
.cs-tag {
  --cs-tag-color: var(--cs-gray-02);
  --cs-tag-text-color: var(--cs-gray-05);
}
::v-deep .cs-tag > * {
  font-weight: 600;
}
.force-one-row {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tags-list:not(.force-one-row) > * {
  margin-bottom: 8px;
}
</style>
