<template>
  <div class="checkOTP page">
    <auth-layout>
      <img slot="image" src="../assets/img/LO_logo_gray.svg" />
      <div slot="text">
        <h1 class="cs-textstyle-page-title">Login with Email</h1>
        <p class="cs-textstyle-paragraph">
          We have sent a code to <strong>{{ this.email }}</strong
          >. Please insert the OTP below.
        </p>
      </div>
      <cs-input
        slot="fields"
        v-model="code"
        placeholder="Submit OTP"
      ></cs-input>

      <p slot="info">
        Didn't receive the code?
        <span
          class="checkOTP__resend-code-timer cs-textstyle-link-text"
          v-if="timerCount > 0"
          >Resend in: {{ timerCount }} seconds</span
        >
        <span
          @click="resendOTP"
          class="checkOTP__resend-code-now cs-textstyle-link-text"
          v-if="timerCount === 0"
          >Resend Now</span
        >
      </p>

      <cs-button
        slot="buttons"
        corners="rounded"
        block
        :disabled="!code.length"
        @click="loginWithOTP"
        >Login</cs-button
      >
    </auth-layout>
  </div>
</template>

<script>
import AuthLayout from "../components/layouts/AuthLayout.vue";
import loginWithOTP from "@/graphql/user/loginWithOTP.gql";
import sendOTP from "@/graphql/user/sendOTP.gql";
import { mapMutations, mapActions } from "vuex";
import { onLogin } from "../vue-apollo.js";

export default {
  components: { AuthLayout },
  data() {
    return {
      code: "",
      timerCount: 30,
    };
  },
  computed: {
    email() {
      return this.$route && this.$route.query && this.$route.query.email
        ? this.$route.query.email
        : "";
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations("auth", {
      setUserLoggedIn: "setUserLoggedIn",
    }),
    ...mapActions("auth", {
      updateUser: "getCurrentUser",
    }),
    async resendOTP() {
      this.timerCount = 30;
      await this.$apollo
        .mutate({
          mutation: sendOTP,
          variables: {
            email: this.email,
          },
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loginWithOTP() {
      if (!this.email || !this.email.length || !this.code || !this.code.length)
        return;

      let { data } = await this.$apollo
        .mutate({
          mutation: loginWithOTP,
          variables: {
            email: this.email,
            otp: this.code,
          },
        })
        .catch((error) => {
          console.log(error);
        });
      if (data && data.login) {
        this.setUserLoggedIn(data.login);
        await onLogin(
          this.$apollo.getClient(),
          localStorage.getItem("dlo_token")
        );
        await this.updateUser();
        this.$router.push("/card");
      }
    },
  },
};
</script>

<style scoped>
.splash {
  height: 100%;
}
.checkOTP__resend-code-now {
  color: var(--cs-primary-base);
}
.checkOTP__resend-code-timer {
  color: var(--cs-primary-light);
}
</style>
