<template>
	<div class="swiper-holder">
		<div class="swiper">
			<div class="swiper-wrapper">
				<slot />
			</div>
		</div>
	</div>
</template>
<script>
import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper.min.css';

export default {
	props: {
		value: {
			type: Number,
			default: 0,
			validator(x) {
				return Number.isInteger(x);
			},
		},
		deck: {
			type: Array
		}
	},
	data() {
		return {
			swiper: null,
		};
	},
	methods: {
		prev() {
			this.swiper.slidePrev();
		},
		next() {
			this.swiper.slideNext();
		},
		onIndexChange() {
			this.$emit(
				'input', 
				this.swiper.activeIndex, 
				this.swiper.previousIndex
			);
		},
	},
	mounted() {
		const startPosition = this.value || 0;
		this.swiper = new Swiper('.swiper', {
			speed: 400,
			loop: false,
			noSwiping: false,
			direction: 'horizontal',
			modules: [Navigation],
			initialSlide: startPosition,
			observer: true, 
			on: {
				afterInit: () => {
					this.$emit('slides-ready');
				},
			}
		});
		this.swiper.on('activeIndexChange', this.onIndexChange);
	},
	watch: {
		deck(newValue, oldValue) {
			if(newValue.length != oldValue.length) {
				this.swiper.updateSlides();
				this.swiper.update();
			}
		}
	},
};
</script>
<style scoped>
/* Support scrolling cards individually */
.swiper-holder {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.swiper {
	height: 100%;
}

.glide__slides {
	white-space: normal;
}
</style>
