<template>
	<div class="action-bar">
		<app-action-bar-button
			@click="dislikeClick"
			icon="cs-icons-like"
			upside-down-icon
			title="See less"
		/>

		<cs-button
			class="action-bar__shuffle_button"
			fill="solid"
			corners="round"
			size="large"
			@click="nextClick"
		>
			<img class="action-bar__shuffle-icon" :src="shuffleIcon" />
		</cs-button>

		<app-save-card-button
			class="action-bar__button"
			:item="card"
			icon="cs-icons-like"
		/>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';

import AppSaveCardButton from '@/components/alternate/SaveCardButton.vue';
import AppActionBarButton from '@/components/alternate/ActionBarButton.vue';

const shuffleIcon = require('@/assets/img/shuffle-icon.svg');

export default {
	components: { AppSaveCardButton, AppActionBarButton },
	props: {
		card: {
			type: Object,
			default: null,
		},
		backDisabled: {
			type: Boolean,
			default: false,
		},
		nextDisabled: {
			type: Boolean,
			default: false,
		},
		nextLoading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			shuffleIcon,
		};
	},
	computed: {
		...mapGetters('auth', {
			isCardSaved: 'userHasSavedItem',
		}),
	},
	methods: {
		dislikeClick() {
			this.$emit('dislike-button');
		},
		nextClick() {
			this.$emit('next-button');
		},
	},
};
</script>
<style scoped>
.action-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 8px var(--cs-page-margin-x);
	padding-bottom: calc(8px + env(safe-area-inset-bottom));
	backdrop-filter: blur(10px);
	display: flex;
	justify-content: space-around;
}
.action-bar__dislike-icon {
	transform: rotate(180deg);
}
.action-bar__shuffle_button {
	--cs-button-padding: 12px;
}
.action-bar__button {
	display: inline-block;
}
.action-bar__button i,
.action-bar__button >>> i {
	font-size: 30px;
}
</style>
