<template>
  <p class="text cs-textstyle-paragraph">
    {{ toggled ? text : truncatedText }}
    <a
      v-if="showReadMore"
      href="javascript:void(0)"
      class="read-more"
      @click="toggle"
    >
      {{ toggled ? lessText : moreText }}
    </a>
  </p>
</template>

<script>
export default {
  props: {
    text: { type: String, required: true },
    limit: { type: Number, default: 25 },
    moreText: { type: String, default: 'Show more' },
    lessText: { type: String, default: 'Show less' },
  },
  data() {
    return { toggled: false };
  },
  computed: {
    truncatedText() {
      return this.text.substring(0, this.limit);
    },
    showReadMore() {
      if (this.text.length <= this.limit) return false;
      return true;
    },
  },
  methods: {
    toggle() {
      this.toggled = !this.toggled;
    },
  },
};
</script>

<style scoped>
.read-more {
  text-decoration: none;
  color: var(--cs-primary-base);
}
.text {
  color: var(--cs-gray-06);
}
</style>
