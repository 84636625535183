const getPrimaryColor = () => {
	return '#1F6DFF';
};

const isDarkThemeOn = () => {
	return document.body.classList.contains('dark');
};

let _watchDarkModeInitialised = false;
const _initDarkModeWatcher = () => {
	if (_watchDarkModeInitialised) return;
	if (window.cordova && window.cordova.platformId === 'android') {
		setInterval(_checkDarkMode, 30000);
	} else {
		const darkMediaQuery = '(prefers-color-scheme: dark)';
		const mediaQueryList = window.matchMedia(darkMediaQuery);
		mediaQueryList.addEventListener('change', _checkDarkMode);
	}
	_checkDarkMode();
	document.addEventListener('resume', _checkDarkMode);
};

const _checkDarkMode = async () => {
	if (window.cordova && window.cordova.platformId === 'android') {
		const dmEnabledAndroid = await _dmEnabledAndroid();
		dmEnabledAndroid ? _applyDarkMode() : _applyLightMode();
	} else {
		const darkMediaQuery = '(prefers-color-scheme: dark)';
		const mediaQueryList = window.matchMedia(darkMediaQuery);
		mediaQueryList.matches ? _applyDarkMode() : _applyLightMode();
	}
};

const _applyDarkMode = () => {
	if (!isDarkThemeOn()) {
		document.body.classList.add('dark');
		_themeIOS(true);
		_themeAndroid(true);
	}
};

const _applyLightMode = () => {
	if (isDarkThemeOn()) {
		document.body.classList.remove('dark');
		_themeIOS(false);
		_themeAndroid(false);
	}
};

const _themeIOS = (dark) => {
	if (!window.cordova || window.cordova.platformId != 'ios') return;
	// Theme Status Bar
	if (window.StatusBar) {
		if (dark && window.StatusBar.styleLightContent)
			window.StatusBar.styleLightContent();
		if (!dark && window.StatusBar.styleDefault) window.StatusBar.styleDefault();
		if (window.StatusBar.overlaysWebView)
			window.StatusBar.overlaysWebView(true);
	}

	// Theme keyboard
	const theme = dark ? 'dark' : 'light';
	window.cordova &&
		window.cordova.platformId === 'ios' &&
		window.Keyboard &&
		window.Keyboard.setKeyboardStyle &&
		window.Keyboard.setKeyboardStyle(theme);
};

const _themeAndroid = (dark) => {
	if (!window.cordova || window.cordova.platformId != 'android') return;

	// Set statusbar to be dark with light icons and prevents statusbar overlaying content
	if (window.StatusBar) {
		// if (dark && window.StatusBar.styleLightContent) window.StatusBar.styleLightContent();
		// if (!dark && window.StatusBar.styleDefault) window.StatusBar.styleDefault();
		// if (window.StatusBar.overlaysWebView)
		//   window.StatusBar.overlaysWebView(true);

		// Currently Android doesn't seem to respect env(safe-inset-top) css property
		// so i've disabled the above lines and use the following:
		// Prevent status bar overlaying content
		if (window.StatusBar.overlaysWebView)
			window.StatusBar.overlaysWebView(false);
		if (dark) {
			if (window.StatusBar.styleLightContent)
				window.StatusBar.styleLightContent();
			if (window.StatusBar.backgroundColorByHexString)
				window.StatusBar.backgroundColorByHexString('#000000');
		} else {
			if (window.StatusBar.styleDefault) window.StatusBar.styleDefault();
			if (window.StatusBar.backgroundColorByHexString)
				window.StatusBar.backgroundColorByHexString('#FFFFFF');
		}
	}
};

// Promise to check if the dark mode plugin is available
const _dmPluginAvailable = () => {
	return new Promise((resolve) => {
		if (!window.cordova.plugins || !window.cordova.plugins.ThemeDetection)
			return resolve(false);
		window.cordova.plugins.ThemeDetection.isAvailable(
			(success) => {
				resolve(success.value);
			},
			() => {
				resolve(false);
			}
		);
	});
};

// Promise to check if dark mode is active on Android
const _dmEnabledAndroid = async () => {
	const isDmPluginAvailable = await _dmPluginAvailable();
	if (!isDmPluginAvailable) return Promise.resolve(false);
	return new Promise((resolve) => {
		window.cordova.plugins.ThemeDetection.isDarkModeEnabled(
			(success) => {
				resolve(success.value);
			},
			() => {
				resolve(false);
			}
		);
	});
};

if (window.cordova) {
	document.addEventListener('deviceready', _initDarkModeWatcher);
} else {
	_initDarkModeWatcher();
}

export default {
	getPrimaryColor,
	isDarkThemeOn,
};
