import { store as $store } from "@/store";
import { getApolloClient } from "@/vue-apollo";

// import router from '@/router/router';

import setNotificationToken from '@/graphql/user/setNotificationToken.gql';

let _initialised = false;
let _notificationsConfigured = false;

const _init = async () => {
	if (_initialised) return Promise.resolve(true);
	if (window.cordova) {
		return new Promise((resolve) => {
			document.addEventListener('deviceready', () => {
				_initialised = true;
				resolve(true);
			});
		});
	} else {
		return Promise.resolve(false);
	}
};

const requestPermissions = async () => {
	const init = await _init();
	if (!init) return Promise.reject('Not cordova project');

  const loggedIn = $store.getters["auth/userLoggedIn"];
	if (!loggedIn) return Promise.reject('User not logged in');

	if (_notificationsConfigured) return Promise.reject('Already configured notifications');
	_notificationsConfigured = true;

	await window.cordova.plugins.firebase.messaging.requestPermission();
	const token = await window.cordova.plugins.firebase.messaging.getToken();

	// setNotificationToken
	console.log('Got Token', token);

	getApolloClient().mutate({
		mutation: setNotificationToken,
		variables: {
			token,
		},
	});

	return Promise.resolve();
};

const setup = async () => {
	try {
		await requestPermissions();
		
		window.cordova.plugins.firebase.messaging.onMessage((msg) => {
			console.log('Foreground Message', msg);
		});

		window.cordova.plugins.firebase.messaging.onBackgroundMessage((msg) => {
			console.log('Background Message', msg);
			// router.push({name: 'card', params: {id: msg.id}});
		}, console.error);
	} catch (err) {
		console.warn('Notifications setup error:', err);
	}
}


export default {
	requestPermissions,
	setup,
};
