<template>
	<div class="action-bar">
		<cs-button
			class="action-bar__back"
			fill="clear"
			@click="backClick"
			:disabled="backDisabled"
		>
			<i class="cs-icons-back-angle-filled"></i> Back
		</cs-button>

		<app-card-primary-action-button class="action-bar__primary" :card="card" />

		<cs-button
			class="action-bar__next"
			fill="clear"
			@click="nextClick"
			:disabled="nextDisabled"
		>
			<cs-spinner v-if="nextLoading && nextDisabled" type='circles'></cs-spinner>
			<template v-else> 
				Next <i class="cs-icons-next-angle-filled"></i>
			</template>
		</cs-button>
	</div>
</template>
<script>
import AppCardPrimaryActionButton from '@/components/cards/CardPrimaryActionButton.vue';

export default {
	components: {
		AppCardPrimaryActionButton,
	},
	props: {
		card: {
			type: Object,
			default: null,
		},
		backDisabled: {
			type: Boolean,
			default: false,
		},
		nextDisabled: {
			type: Boolean,
			default: false,
		},
		nextLoading: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		primaryClick() {
			this.$emit('primary-button');
		},
		backClick() {
			this.$emit('back-button');
		},
		nextClick() {
			this.$emit('next-button');
		},
	},
};
</script>
<style scoped>
.action-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 16px var(--cs-page-margin-x);
	padding-bottom: calc(16px + env(safe-area-inset-bottom));
	backdrop-filter: blur(10px);
	display: flex;
}
.action-bar .action-bar__primary {
	margin: 0 16px;
}
.cs-spinner {
	display: inherit;
	position: absolute;
	transform: scale(0.4);
	--cs-spinner-color: var(--cs-primary-base);
}
</style>
