// GQL queries
import claimInvitationCode from '@/graphql/user/claimInvitationCode.gql';
import getUserInfo from '@/graphql/user/getUserInfo.gql';
import setOnboarded from '@/graphql/user/setOnboarded.gql';
import setItemSaved from '@/graphql/item/setItemSaved.gql';

import { getApolloClient } from '@/vue-apollo';

import $toast from '@/services/toast';

export default {
	namespaced: true,
	state: {
		user: null,
		userOnboarded: localStorage.dlo_onboarded,
		userLoggedIn: localStorage.dlo_token,
		userSavedItemIds: null,
		userCommentedItems: null,
		userAttendingEventsIds: null,
		userVotedPollsIds: [],
	},
	getters: {
		user: (state) => state.user,
		userId: (state) => (state.user ? state.user.id : null),
		userOnboarded: (state) => state.userOnboarded,
		userLoggedIn: (state) => state.userLoggedIn,
		userSavedItemIds: (state) => {
			return state.userSavedItemIds || [];
		},
		userHasSavedItem: (state, getters) => (itemId) => {
			const ids = getters.userSavedItemIds;
			return ids.includes(itemId);
		},
		userCommentedItemIds: (state) => {
			const items = state.userCommentedItems || [];
			return items.map((item) => item.item_id);
		},
		userHasCommentedItem: (state, getters) => (itemId) => {
			const ids = getters.userCommentedItemIds;
			return ids.includes(itemId);
		},
		userIsAttendingEvent: (state) => (itemId) => {
			const ids = state.userAttendingEventsIds;
			return ids.includes(itemId);
		},
		userVotedPoll: (state) => (itemId) => {
			const ids = state.userVotedPollsIds;
			return ids.includes(itemId);
		},
	},
	mutations: {
		setUser(state, userData) {
			state.user = userData;
		},
		setUserOnboarded(state, val) {
			val
				? (localStorage.dlo_onboarded = 1)
				: localStorage.removeItem('dlo_onboarded');
			state.userOnboarded = localStorage.dlo_onboarded;
		},
		setUserLoggedIn(state, val) {
			val
				? (localStorage.dlo_token = val)
				: localStorage.removeItem('dlo_token');
			state.userLoggedIn = val;
		},
		setUserSavedItemIds(state, val) {
			state.userSavedItemIds = val;
		},
		setUserCommentedItems(state, val) {
			state.userCommentedItems = val;
		},
		setUserAttendingEvents(state, val) {
			state.userAttendingEventsIds = val;
		},
		setUserVotedPoll(state, val) {
			state.userVotedPollsIds = val;
		},
		removeAttendingEventId(state, val) {
			if (state.userAttendingEventsIds.filter((id) => id === val)) {
				state.userAttendingEventsIds.splice(
					state.userAttendingEventsIds.indexOf(val),
					1
				);
			}
		},
		setAttendingEvent(state, val) {
			if (!state.userAttendingEventsIds.includes(val)) {
				state.userAttendingEventsIds.push(val);
			}
		},
		removeVotedPollId(state, val) {
			if (state.userVotedPollsIds.filter((id) => id === val)) {
				state.userVotedPollsIds.splice(
					state.userVotedPollsIds.indexOf(val),
					1
				);
			}
		},
		setVotedPoll(state, val) {
			if (!state.userVotedPollsIds.includes(val)) {
				state.userVotedPollsIds.push(val);
			}
		},
		addSavedItem(state, id) {
			if (!state.userSavedItemIds.includes(id)) {
				state.userSavedItemIds.push(id);
			}
		},
		removeSavedItem(state, id) {
			const idsToKeep = state.userSavedItemIds.filter((e) => e !== id);
			state.userSavedItemIds = idsToKeep;
		},
		setComment(state, { id, item_id }) {
			state.userCommentedItems.push({
				id: id,
				item_id: item_id,
			});
		},
		removeCommentedItem(state, val) {
			if (state.userCommentedItems.filter((e) => e.item_id === val)) {
				var idx = state.userCommentedItems.findIndex(
					(p) => p.item_id == val.item_id
				);
				state.userCommentedItems.splice(idx, 1);
			}
		},
	},
	actions: {
		async claimInvitation({ commit }, invitationCode) {
			const { data } = await getApolloClient()
				.mutate({
					mutation: claimInvitationCode,
					variables: {
						invitationCode: invitationCode,
					},
				})
				.catch((error) => {
					console.log(error);
				});
			commit('setUserLoggedIn', data.claim_invitation_code);
		},

		async getCurrentUser({ commit }) {
			const {
				data: { get_me },
			} = await getApolloClient().query({
				query: getUserInfo,
			});
			commit('setUser', get_me);
			commit('setUserOnboarded', get_me && get_me.onboarded_at);
			commit('setUserSavedItemIds', get_me && get_me.list_saved_item_ids);
			commit('setUserCommentedItems', get_me && get_me.list_commented_item);
			commit(
				'setUserAttendingEvents',
				get_me && get_me.list_attending_events_ids
			);
			return get_me;
		},

		async onboardUser({ commit }) {
			commit('setUserOnboarded', true);
			getApolloClient()
				.mutate({
					mutation: setOnboarded,
				})
				.catch((error) => {
					console.log(error);
				});
		},

		logout({ commit }) {
			localStorage.removeItem('dlo_token');
			commit('setUserLoggedIn', false);
			commit('setUser', null);
		},

		async saveItem({ commit }, item) {
			commit('addSavedItem', item.id);
			const { data } = await getApolloClient()
				.mutate({
					mutation: setItemSaved,
					variables: {
						saved: true,
						itemId: item.id,
					},
				})
				.catch((error) => {
					$toast.show(`Unable to save: ${error.message}`);
					commit('removeSavedItem', item.id);
				});
			if (!data) {
				commit('removeSavedItem', item.id);
			}
		},
		async unsaveItem({ commit }, item) {
			commit('removeSavedItem', item.id);
			const { data } = await getApolloClient()
				.mutate({
					mutation: setItemSaved,
					variables: {
						saved: false,
						itemId: item.id,
					},
				})
				.catch((error) => {
					$toast.show(`Unable to unsave: ${error.message}`);
					commit('addSavedItem', item.id);
				});
			if (!data) {
				commit('addSavedItem', item.id);
			}
		},
	},
};
