<template>
  <div class="auth-layout">
    <div class="main">
      <secondary-header allowBack></secondary-header>
      <div class="content">
        <div class="image">
          <slot name="image"></slot>
        </div>
        <div class="text">
          <slot name="text"></slot>
        </div>
        <div class="fields">
          <slot name="fields"></slot>
        </div>
        <div class="info">
          <slot name="info"></slot>
        </div>
        <slot></slot>
      </div>
    </div>
    <div class="buttons">
      <slot name="buttons-info"></slot>
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
import SecondaryHeader from "../general/SecondaryHeader.vue";
export default {
  components: {
    SecondaryHeader,
  },
};
</script>

<style scoped>
.auth-layout {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  height: 100%;
}
.buttons {
  padding: 0px;
}
.content {
  text-align: center;
  padding: 16px 16px 0px 16px;
}
.text {
  margin-bottom: 48px;
}
.image {
  height: 54px;
}
</style>
