<template>
  <div class="confirm-email page ">
    <auth-layout>
      <div slot="image" class="confirm-email__picture">
        <cs-avatar size="large" :picture="user.photo_url" :name="`${user.first_name} ${user.last_name}`" />
      </div>
      <div slot="text">
        <h1 class="cs-textstyle-page-title">
          Hi &#128075;, {{user ? `${user.first_name} ${user.last_name}` : ''}}
        </h1>
        <h3 class="cs-textstyle-item-heading">Welcome to Leaders.org</h3>
        <p class="cs-textstyle-paragraph">
          Please confirm your email address. We’ll use this to invite you to events, 
          and introduce you to other leaders in the community.
        </p>
      </div>
      <cs-input slot="fields" v-model="email" type="email" placeholder="Email address">
      </cs-input>
      <cs-button slot="buttons" corners="rounded" block :disabled="!email.length" @click="confirmPrimaryEmail">
        Confirm email
      </cs-button>
    </auth-layout>
  </div>
</template>

<script>
import AuthLayout from '../components/layouts/AuthLayout.vue'
import setPrimaryEmail from '../graphql/user/setPrimaryEmail.gql'

import { mapState } from 'vuex';

import $dialog from '@/services/dialog';

export default {
  components: {AuthLayout },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
    }),
    initialEmail() {
      return this.user
              && this.user.emails
              && this.user.emails.length 
            ? this.user.emails[0].email 
            : '';
    },
  },
  methods: {
    async confirmPrimaryEmail() {
      const { data } = await this.$apollo.mutate({
        mutation: setPrimaryEmail,
        variables: {
          email: this.email
        },
      }).catch(error => {
        console.log(error)
      });

      if(data.set_primary_email) {
        this.$router.push('/card');
      } else {
        $dialog.alert(`We could not confirm this email address: ${this.email}. Please try again.`);
      }
    },
  },
  mounted() {
    this.email = this.initialEmail;
  },
  watch: {
    initialEmail(value) {
      if(value && value.length) {
        this.email = value;
      }
    }
  },
}
</script>

<style scoped>

.confirm-email {
  height: 100%;
}
.confirm-email__picture{
  display: flex;
  height: 100px !important;
  justify-content: center;
}
* >>> .image{
  height: 80px;
}
</style>
