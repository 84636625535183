<template>
  <div class="secondary-header">
    <router-link :to="iconRoute" v-if="!allowBack">
      <div class="icon"> 
        <i :class="icon"></i>
      </div>
    </router-link>
    <div class="icon" @click="$router.go(-1)"> 
        <i :class="icon ? icon : 'cs-icons-back-arrow'"></i>
      </div>
    <div class="title cs-textstyle-section-heading">{{title}}</div>
    <div class="slot-buttons">
      <slot name="buttons" ></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    iconRoute: {
      type: String,
    },
    title: {
      type: String,
    },
    allowBack: {
      type: Boolean,
    } 
  }
}
</script>

<style scoped>
.secondary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: calc(0px - var(--cs-page-margin-x));
  margin-right: calc(0px - var(--cs-page-margin-x));
}

.title {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 60%;
  text-align: center;
}
.slot-buttons >>> i {
  font-size: 24px;
  padding: 16px 8px;
}
.slot-buttons >>> i:last-child {
  padding-right: 16px;
}
.icon {
  font-size: 24px;
  padding: 16px;
}
</style>