<template>
  <app-modal :show="show" @close="$emit('close')" :title="pageTitle">
    <app-loader v-if="loading" />
    <template v-if="cardData && cardConfig">
      <app-card v-if="cardConfig.card_componentName" :card="cardData">
        <component
          v-bind:is="cardConfig.card_componentName"
          slot="card-content"
          :card="cardData"
          @input="onInput"
        >
        </component>
      </app-card>
    </template>
    <app-empty-state
      v-if="noResult"
      size="medium"
      title="No results found"
      :description="`Sorry we couldn't find that item in our system`"
    />
    <app-empty-state
      v-if="error"
      size="medium"
      title="Error"
      :description="error"
    />
    <div class="lo-card-modal__footer">
      <app-card-primary-action-button
        v-if="cardData && cardConfig"
        slot="footer"
        class="lo-card-modal__primary-action-button"
        :card="cardData"
      />
    </div>
  </app-modal>
</template>

<script>
import AppModal from '@/components/modals/Modal.vue';

import AppEmptyState from '@/components/general/EmptyState.vue';
import AppLoader from '@/components/general/Loader.vue';

import AppCard from '@/components/cards/Card.vue';
import PersonCard from '@/components/cards/PersonCard.vue';
import PollCard from '@/components/cards/PollCard.vue';
import EventCard from '@/components/cards/EventCard.vue';
import ArticleCard from '@/components/cards/ArticleCard.vue';
import AppCardPrimaryActionButton from '@/components/cards/CardPrimaryActionButton.vue';

import { itemConfig as config } from '@/services/cardConfig';

export default {
  components: {
    AppModal,
    AppEmptyState,
    AppLoader,
    AppCard,
    PersonCard,
    PollCard,
    EventCard,
    ArticleCard,
    AppCardPrimaryActionButton,
  },
  props: {
    itemId: {
      type: String,
      default: null,
    },
    itemType: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    noResult() {
      if (this.error) return false;
      return !!(this.canQuery && !this.loading && !this.cardData);
    },
    canQuery() {
      return !!(this.itemId && this.itemType);
    },
    cardConfig() {
      if (!this.itemType) return null;
      return config[this.itemType];
    },
    pageTitle() {
      if (!this.cardConfig) return '';
      return this.cardConfig.card_title;
    },
  },
  data() {
    return {
      loading: false,
      cardData: null,
      error: null,
    };
  },
  methods: {
    async refresh() {
      if (this.loading) return;
      if (!this.canQuery) {
        this.cardData = null;
        this.error = null;
        return;
      }

      const itemConfig = config[this.itemType];
      if (!itemConfig || !itemConfig.card_item_query) return;

      this.loading = true;
      const { data } = await this.$apollo
        .query({
          query: itemConfig.card_item_query,
          variables: {
            id: this.itemId,
          },
        })
        .catch((e) => {
          this.loading = false;
          this.error = e.message;
        });
      this.loading = false;
      this.cardData = data[itemConfig.card_item_query_key];
    },
    onInput($event) {
      this.$store.dispatch('cards/setCardInput', {
        card: this.cardData,
        input: $event,
      });
      this.cardConfig &&
        this.cardConfig.onInput &&
        this.cardConfig.onInput($event, this.cardData);
    },
  },
  watch: {
    itemId() {
      this.refresh();
    },
    itemType() {
      this.refresh();
    },
  },
};
</script>

<style scoped>
.lo-card-modal {
  padding: 5px;
}
.lo-card-modal__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px var(--cs-page-margin-x);
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
  backdrop-filter: blur(10px);
}
</style>
