<template>
  <cs-modal :show="show" @close="$emit('close')">
    <div slot="header" class="cs-textstyle-section-heading title">
      {{ title }}
    </div>
    <div slot="body" class="modal-body">
      <slot></slot>
    </div>
    <div slot="fallback">
      <i class="cs-icons-close-filled close-icon" @click="$emit('close')"></i>
    </div>
  </cs-modal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
    },
    title: {
      type: String,
    },
  },
};
</script>

<style scoped>
i.close-icon {
  font-size: 24px;
  padding: 16px;
  padding-right: 12px;
}
.modal-body {
  padding-top: var(--cs-card-padding-y);
  padding-bottom: 26px;
}
</style>
