import router from '@/router/router';
import $dialog from '@/services/dialog';

let _watchBackButtonInitialised = false;

const _initBackButtonWatcher = () => {
  if (_watchBackButtonInitialised) return;
  _watchBackButtonInitialised = true;
  if (window.cordova.platformId !== 'android') return;
  document.addEventListener('backbutton', async () => {
    const dialogs = document.querySelectorAll('.dialog.dialog--open');
    const actionsheets = document.querySelectorAll('.sheet.sheet--open');
    const modals = Array.from(document.querySelectorAll('.cs-modal'));
    const openModals = modals.filter(modal => {
      const hasContent = modal.querySelector('.cs-modal__popup');
      return hasContent;
    });
    const currentRoute = router.currentRoute;

    if (dialogs.length) {
      const topDialog = dialogs[dialogs.length - 1];
      topDialog.dispatchEvent(new CustomEvent('backbutton'));
    } else if (actionsheets.length) {
      const topSheet = actionsheets[actionsheets.length - 1];
      topSheet.dispatchEvent(new CustomEvent('close'));
    } else if (openModals.length) {
      const topModal = openModals[openModals.length - 1];
      const topModalBackdrop = topModal.querySelector('.cs-modal__backdrop');
      topModalBackdrop && topModalBackdrop.click();
    } else if (currentRoute.meta && currentRoute.meta.promptClose) {
      const confirmClose = await $dialog.confirm({
        title: 'Close App',
        message: 'Are you sure you want to close Discover?',
        okayLabel: 'Yes',
        cancelLabel: 'No',
      });
      if (confirmClose) navigator.app &&
        navigator.app.exitApp &&
        navigator.app.exitApp();
    } else if (!currentRoute.meta || !currentRoute.meta.preventBack) {
      router.go(-1);
    }
  })
}

if (window.cordova) {
	document.addEventListener('deviceready', _initBackButtonWatcher);
}