<template>
  <div class="poll-card">
    <tag-list
      v-if="card.tags && card.tags.length"
      :tags="card.tags"
      :forceOneRow="true"
    ></tag-list>
    <div class="title cs-textstyle-item-heading">{{ card.title }}</div>
    <div class="section">
      <cs-button-group
       @click.stop
        block
        :buttons="buttons"
        fill="outline"
        :values="selectedButton"
        @input="onButtonSelected"
      ></cs-button-group>
    </div>
  </div>
</template>

<script>
import TagList from "../general/TagList.vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    TagList,
  },
  props: {
    card: {
      type: Object,
    },
  },
  data() {
    return {
      selectedOption: null,
    };
  },
  computed: {
		...mapGetters('cards', {
			getCardInput: 'getCardInput',
		}),
    createdDate() {
      const date = new Date(this.card.created_on);
      return date.toLocaleDateString("en-uk", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    buttons() {
      return this.card.options.map((elem) => {
        return {
          value: elem.id,
          label: elem.title,
        };
      });
    },
    selectedButton() {
      if (this.selectedOption) return this.selectedOption;
      if (this.card) return this.getCardInput(this.card.id);
      return [];
    },
  },
  methods: {
    onButtonSelected($event) {
      this.selectedOption = $event;
      this.$emit("input", $event);
    },
  },
};
</script>

<style scoped>
.poll-card {
  margin-top: var(--cs-card-content-margin-y);
}
.section {
  margin-bottom: 16px;
  margin-top: 16px;
}
.section .section-title {
  margin-bottom: 8px;
}
.title,
.section-title {
  color: var(--cs-gray-06);
}
i {
  font-size: 16px;
  margin-right: 8px;
}
</style>
