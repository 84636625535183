import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import cards from './modules/cards';

Vue.use(Vuex);

export let store = new Vuex.Store({
  modules: {
    auth,
    cards,
  },
});
