import $theme from '@/services/theme';

// If we are opening in the app, use the SafariViewController plugin (better experience)
// Otherwise use fallback browser
const openURL = async (url) => {
  const supportSVC = await svcAvailable();
  if (!supportSVC) return openFallback(url);

  const tintColor = $theme.getPrimaryColor();
  const barColor = $theme.isDarkThemeOn() ? '#040621' : '#F5F7F6';

  const options = {
    url,
    hidden: false, // default false. You can use this to load cookies etc in the background (see issue #1 for details).
    animated: true, // default true, note that 'hide' will reuse this preference (the 'Done' button will always animate though)
    transition: 'slide', // (this only works in iOS 9.1/9.2 and lower) unless animated is false you can choose from: curl, flip, fade, slide (default)
    enterReaderModeIfAvailable: false, // default false
    tintColor, // button text color
    // barColor, // this crashes iOS (tested 15.6)
    toolbarColor: barColor,
  }

  window.SafariViewController.show(options,
    (result) => {
      // this success handler will be invoked for lifecycle events
      // result.event can be one of 'loaded', 'opened', 'closed';
      // Note: 'opened' did not seem to fire on Android
      console.log('safari view controller event:', result);
    },
    (msg) => {
      // No documentation about what this event handler does
      console.log("KO: " + msg);
    });
}

// Opens link using InAppBrowser plugin
const openFallback = async (url) => {
  if (!window.cordova || !window.cordova.InAppBrowser || !window.cordova.InAppBrowser.open)
    return openExternal(url);

  const tintColor = $theme.getPrimaryColor();
  const barColor = $theme.isDarkThemeOn() ? '#040621' : '#F5F7F6';

  // NOTE: more options available including clearing cache, etc.
  // See: https://cordova.apache.org/docs/en/11.x/reference/cordova-plugin-inappbrowser/index.html
  const options = {
    // All platforms
    location: 'yes', // show/hide the entire top toolbar (url, forward/back buttons, close/done button, etc)

    // Both Android & iOS
    // closebuttoncaption: 'Done', //in place of X or Done close button
    closebuttoncolor: tintColor,
    hidenavigationbuttons: 'no', // hides forward/back icons in top-left of the bar
    mediaPlaybackRequiresUserAction: 'yes', // no autoplay
    navigationbuttoncolor: tintColor,
    toolbarcolor: barColor,

    // Android
    footer: 'no', // shows a "footer" with closebuttoncaption (like on iOS)
    hardwareback: 'yes', // hardware back button
    lefttoright: 'yes', // reverse the order of top bar: X, url, forward/back
    shouldPauseOnSuspend: 'yes', // pause audio/video when the app is minimized
    fullscreen: 'no', //
    zoom: 'no', // disable zoom controls
    
    // iOS
    toolbar: 'yes',
    toolbartranslucent: 'yes',
    toolbarposition: 'bottom'
  };
  return window.cordova.InAppBrowser.open(url, options);
}

// Attempts to open link in the system browser
const openExternal = async (url) => {
  if (window.cordova && window.cordova.InAppBrowser && window.cordova.InAppBrowser.open) {
    window.cordova.InAppBrowser.open(url, '_system');
  } else {
    window.open(url, '_system');
  }
}

const svcAvailable = async () => {
  return new Promise((resolve) => {
    if (window.SafariViewController) {
      window.SafariViewController.isAvailable(resolve);
    } else {
      resolve(false);
    }
  });
}


export default {
  open: openURL,
  openExternal,
  openFallback,
}