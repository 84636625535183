<template>
  <div class="loader-container">
    <div class="basic-circle"></div>
    <div class="lighter-circle circle"></div>
    <div class="darker-circle circle"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.loader-container {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}
.basic-circle {
  box-shadow: 0px 4px 8px 0px #1f6dff40;
  width: 16px;
  height: 16px;
  background-color: #1f6dff;
  border-radius: 50%;
  margin: 100px auto;
}
.lighter-circle {
  width: 130px;
  height: 130px;
  animation: grow 2s 1;
  animation-iteration-count: infinite;
}
.darker-circle {
  width: 115px;
  height: 115px;
  animation: grow 2s 1;
  animation-delay: 250ms;
  animation-iteration-count: infinite;
}
.circle {
  background: #1f6dff;
  opacity: 0.15;
  position: absolute;
  margin: auto;
  border-radius: 100%;
  overflow: hidden;
}

@keyframes grow {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0);
  }
}
</style>
