import { render, staticRenderFns } from "./CheckOTP.vue?vue&type=template&id=5951a31c&scoped=true&"
import script from "./CheckOTP.vue?vue&type=script&lang=js&"
export * from "./CheckOTP.vue?vue&type=script&lang=js&"
import style0 from "./CheckOTP.vue?vue&type=style&index=0&id=5951a31c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5951a31c",
  null
  
)

export default component.exports