import eventConfig from "@/services/cardConfig/event";
import articleConfig from "@/services/cardConfig/article";
import personConfig from "@/services/cardConfig/person";
import pollConfig from "@/services/cardConfig/poll";
import goalConfig from "@/services/cardConfig/goal";

const itemConfig = {
  Event: eventConfig,
  Article: articleConfig,
  Person: personConfig,
  Poll: pollConfig,
  Goal: goalConfig,
};

export { itemConfig };

/**
 * If you need mock data to test, uncomment this, 
 * export it, and then import it where you need
 * 
 * const recommendationsMock: [
        {
          __typename: 'Poll',
          title: 'What is your main business challenge?',
          options: [{
            id: 1,
            text: 'Raising capital',
          },{
            id: 2,
            text: 'Finding talent',
          },{
            id: 3,
            text: 'Scaling my business',
          },{
            id: 4,
            text: 'Reducing costs',
          },{
            id: 5,
            text: 'Other (mention in comments)',
          }],
          created_on: '2022-07-17T16:00:00',
          positiveInteractions: 15 
        },
        {
          __typename: 'Article',
          title: 'What is cloud hosting and how does it work?',
          content_snippet: 'Meet up to 15 C-Suite leaders at our first virtual networking event for senior business leaders in New York. Built on the concept of "speed-networking", each participant connects one-on-one with another',
          creator: 'Micheal Johndas',
          published_at: '2022-07-17T16:00:00',
          categories: ["Business", "Art", "Leadership", "Tech"],
          link: 'https://medium.com',
          photo_url: 'https://picsum.photos/400',
          source_name: 'Medium',
          positiveInteractions: 15 
        },
        {
          __typename: 'Person',
          first_name: 'Micheal',
          last_name: 'Johndas',
          occupation: 'Cheif Technology Officer at Google',
          photo_url: 'https://picsum.photos/500',
          primary_email: 'mjohndas@gmail.com',
          city: 'New York',
          onboarded: true,
          summary: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since. `,
          interests: [{
            interest: "Business"
          }, {
            interest: "Art"
          }, {
            interest: "Leadership"
          }, {
            interest: "Tech"
          }],
          company: 'Google',
          positiveInteractions: 14,
        },
        {
          __typename: 'Event',
          title: 'CTO Meetup - New York Networking Hour',
          host: {
            first_name: 'Micheal',
            last_name: 'Johndas',
            photo_url: 'https://picsum.photos/500',
          },
          starts_at: '2022-07-17T16:00:00',
          ends_at: '2022-07-17T17:00:00',
          city: {
            name: 'San Francisco',
            country: 'USA',
          },
          location: '3rd Avenue 14',
          is_online: false,
          price: 120,
          currency: 'EUR',
          positiveInteractions: 16,
          description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since. `,
          tags: ["Business", "Art", "Leadership", "Tech"],
          photo_url: 'https://picsum.photos/600',
        },
        {
          __typename: 'Person',
          first_name: 'Kelly',
          last_name: 'Smith',
          company: 'Google',
          occupation: 'Chief Technology Officer',
          photo_url: 'https://picsum.photos/500',
          primary_email: 'Kelly@gmail.com',
          city: 'San Francisco',
          onboarded: true,
          positiveInteractions: 14,
          summary: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since. `,
          interests: ["Business", "Art", "Leadership", "Tech"]
        },
        {
          type: 'Person',
          first_name: 'Amir',
          last_name: 'O',
          company: 'Google',
          occupation: 'Chief Technology Officer',
          photo_url: 'https://picsum.photos/500',
          primary_email: 'Amir@gmail.com',
          city: 'New York',
          onboarded: true,
          positiveInteractions: 14,
          summary: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since. `,
          interests: ["Business", "Art", "Leadership", "Tech"]
        }
      ]
 * 
 */
