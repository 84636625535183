<template>
  <div class="splash">
    <div class="logo">
      <img src="../assets/img/LO_logo_white.svg"/>
    </div>
    <div class="buttons">
      <router-link to="/signup">
        <cs-button  corners="rounded" block>
          Login with Invitation Code
        </cs-button>
      </router-link>
      <router-link to="/login">
        <cs-button class="white-button" corners="rounded" block>
          Login with Email
        </cs-button>
      </router-link>
      <div class="delimiter"> OR </div>
      <router-link to="/request-invite">
        <cs-button fill="outline" variant="default" corners="rounded" block>
          Request invitation
        </cs-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.splash {
  background-image: url('../assets/img/Splash.png');
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
}
.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: var(--cs-page-margin-x);
  box-sizing: border-box;
}

.delimiter {
  text-align: center;
  margin: 16px;
  color: var(--cs-gray-03);
}
.white-button {
  --cs-button-color : var(--cs-gray-00);
  --cs-button-color-hover: var(--cs-gray-00);
  --cs-button-text-color: var(--cs-gray-06);
  --cs-button-text-color-hover: var(--cs-gray-06);
}

.buttons a:not(:last-child) .cs-button {
  margin-top: 16px;
}
</style>
