<template>
  <div class="meet-request-modal-content">
    <cs-card>
      <div class="questions" v-if="questions.length" slot="body">
        <app-add-progress-bar :step="step + 1" />
        <div class="question-box">
          <component
            v-bind:is="currentQuestion.componentName"
            :question="currentQuestion"
            @input="checkValue"
          >
          </component>
        </div>
        <cs-button
          corners="rounded"
          block
          :disabled="isDisabled"
          @click="handleNext"
          >{{ buttonLabel }}</cs-button
        >
      </div>
    </cs-card>
  </div>
</template>

<script>
import requestMeeting from '@/graphql/people/requestMeeting.gql';
import AppAddProgressBar from '@/components/general/AddProgressBar.vue';
import MultipleAnswers from '@/components/questions/MultipleAnswers.vue';
import SingleAnswers from '@/components/questions/SingleAnswers.vue';

import $dialog from '@/services/dialog';
import { analytics } from '@/services/utils';

export default {
  data() {
    return {
      step: 0,
      isDisabled: true,
    };
  },
  components: { AppAddProgressBar, MultipleAnswers, SingleAnswers },
  computed: {
    questions() {
      return [
        {
          title: `How would you like to meet ${this.item.first_name} ${this.item.last_name}? If approved we will introduce you.`,
          choiceType: 'single',
          componentName: 'SingleAnswers',
          required: true,
          options: ['Group Coffee', 'Group Lunch', '1:1 Call', '1:1 Coffee'],
          value: null,
        },
        {
          title: `Why would you like to meet ${this.item.first_name} ${this.item.last_name}? If approved we will introduce you.`,
          choiceType: 'multiple',
          componentName: 'MultipleAnswers',
          textareaPlaceholder: 'Let us know why you would like to meet them',
          required: true,
          options: [
            'Raise Capital',
            'Build Network',
            'Pitch My Product',
            'Make New Friends',
            'Interesting Role',
            'Other',
          ],
          otherAnswer: '',
          value: [],
        },
      ];
    },
    currentQuestion() {
      return this.questions[this.step];
    },
    buttonLabel() {
      return this.step + 1 < this.questions.length
        ? 'Continue'
        : 'Request Introduction';
    },
  },
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    async handleNext() {
      if (this.step + 1 < this.questions.length) {
        this.step += 1;
        this.isDisabled = true;
      } else {
        await this.requestIntroduction();
      }
    },
    cleanReasonsData(question) {
      if (!question.value.length) return;

      const otherIndex = question.value.indexOf('Other');
      if (otherIndex > -1) {
        question.value.splice(otherIndex, 1);
        question.value.push(question.otherAnswer);
      }
    },
    async requestIntroduction() {
      analytics({eventName: 'person_request-introduction'})
      this.cleanReasonsData(this.questions[1]);

      const { data } = await this.$apollo
        .mutate({
          mutation: requestMeeting,
          variables: {
            how: [this.questions[0].value],
            why: this.questions[1].value,
            personId: this.item.id,
          },
        })
        .catch((error) => {
          console.log(error);
        });
      if (data && data.requestMeeting) {
        $dialog.alert(
          'Your introduction request is being analysed by our team. We will let you know as soon as possible what are the next steps.'
        );
        this.$emit('close');
      } else {
        $dialog.alert('Something went wrong. Please try again.');
      }
    },
    checkValue(value) {
      this.isDisabled = !value || !value.length;
    },
  },
};
</script>

<style scoped>
.questions .cs-button {
  margin-top: 24px;
}
</style>
