<template>
  <div class="poll-modal-content">
    <app-loader v-if="loading" />
    <cs-card slot="body" v-if="poll_responses && comments">
      <div slot="body">
        <div class="title cs-textstyle-item-heading">{{ item.title }}</div>

        <!-- poll result  -->
        <div class="poll-box" v-for="item in pollResults" :key="item.id">
          <div class="poll-progress">
            <span
              class="result-title"
              :class="{ percentActive: item.isSelected }"
              >{{ item.title }}</span
            >
            <div
              class="bar"
              :style="{ width: item.percentage }"
              :class="{ active: item.isSelected }"
            ></div>
          </div>
          <div class="poll-percentage">
            {{ Number(item.percentage.split("%")[0]).toFixed(2) + "%" }}
          </div>
        </div>
        <!-- comment box  -->
        <div class="title">
          <span class="cs-textstyle-paragraph-bold">Comments</span
          ><span class="comments-number"> {{ comments.length }}</span>
        </div>
        <div class="comments">
          <app-comments :comments="comments" @update="update_comment" />
        </div>
        <empty-state
          v-if="comments && !comments.length"
          size="small"
          title="No Comments Yet"
          description="No previous comments for this poll. Be the first one to comment."
          imageName="NoCommentsPlaceholder.png"
        />
        <!-- message box -->
        <div class="border"></div>
        <cs-message-box
          v-model="comment"
          class="message-box"
          placeholder="Write a comment.."
          @send="send"
        />
      </div>
    </cs-card>
  </div>
</template>

<script>
import listPollResponses from "@/graphql/polls/listPollResponses.gql";
import listItemComments from "@/graphql/comments/listItemComments.gql";
import insertComment from "@/graphql/comments/insertComment.gql";
import AppComments from "@/components/general/CommentList.vue";
import EmptyState from "@/components/general/EmptyState.vue";
import AppLoader from "@/components/general/Loader.vue";
import { analytics } from '@/services/utils';

export default {
  components: { AppComments, EmptyState, AppLoader },
  props: {
    item: {
      type: Object,
    },
  },
  apollo: {
    poll_responses: {
      query: listPollResponses,
      variables() {
        return {
          pollId: this.poll.id,
        };
      },
      result(data) {
        const responses = data.data.poll_responses;

        const counts = responses.reduce((r, { poll_option_id }) => {
          r[poll_option_id] = (r[poll_option_id] || 0) + 1;
          return r;
        }, {});
        this.pollResults = this.poll_options.map((o) => ({
          ...o,
          percentage:
            (((counts[o.id] || 0) * 100) / responses.length).toString() + "%",
        }));
      },
    },
    comments: {
      query: listItemComments,
      variables() {
        return {
          itemId: this.poll.id,
        };
      },
    },
  },
  data() {
    return {
      poll_options: [],
      pollResults: [],
      comment: "",
      openMenuForCommentId: "",
    };
  },
  computed: {
    poll() {
      return this.item;
    },
    loading() {
      return (
        this.$apollo.queries.comments.loading &&
        this.$apollo.queries.poll_responses.loading
      );
    },
  },
  mounted() {
    this.$store.commit('auth/setVotedPoll', this.item.id);
    this.$apollo.queries.poll_responses.refetch();
    this.poll.options.map((item) => this.poll_options.push(item));
    this.poll_options.filter((item) => {
      return this.item.cardInput[0].value === item.id
        ? (item.isSelected = true)
        : (item.isSelected = false);
    });
  },
  methods: {
    async send() {
      await this.$apollo
        .mutate({
          mutation: insertComment,
          variables: {
            itemId: this.poll.id,
            message: this.comment,
          },
        })
        .catch((error) => {
          console.log(error);
        });
      analytics({eventName: 'comment_leave-comment', route: 'card-deck'})
      this.$apollo.queries.comments.refetch();
    },
    update_comment() {
      analytics({eventName: 'comment_delete-comment', route: 'card-deck'})
      this.$apollo.queries.comments.refetch();
    },
  },
};
</script>

<style scoped>
.poll-modal-content {
  color: var(--cs-gray-06);
  font-weight: 700;
}
.title {
  margin-bottom: 24px;
  color: var(--cs-gray-06);
}
.poll-box {
  display: flex;
  justify-content: space-between;
}
.poll-progress {
  margin-bottom: 16px;
  border-radius: 3px;
  width: 500px;
  position: relative;
}

.result-title {
  color: var(--cs-gray-06);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5%;
  font-weight: 700;
  font-size: 14px;
}

.bar {
  height: 40px;
  background-color: var(--cs-gray-01);
}
.active {
  background-color: #e1ecff;
}
.percentActive {
  color: var(--cs-primary-base);
}
.poll-percentage {
  margin-left: 2px;
  width: 40px;
  padding-top: 10px;
  font-size: 14px;
  color: var(--cs-gray-05);
  font-weight: 400;
}
.comments-number {
  color: var(--cs-gray-04);
  margin-left: 4px;
}
* >>> .message-box.message-box {
  box-shadow: none;
  padding-top: 15px;
  ---cs-message-box-padding-mobile: 0px;
}
.border {
  border-top: 1px solid var(--cs-gray-01);
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 5px;
}
</style>
