<template>
  <div class="profile-page">
    <secondary-header allowBack title="My Profile">
      ><cs-button
        variant="danger"
        slot="buttons"
        fill="clear"
        @click.prevent.stop="logOut"
        >Logout</cs-button
      >
    </secondary-header>
    <profile-information v-if="user" :card="user" />
    <!-- <div class="profile-page__tabs">
			<cs-page-tabs align="justify" :no-border="true" :tabs="tabs" />
		</div> -->
    <h2 class="cs-textstyle-item-heading">Saved Items</h2>
    <div v-if="saved_items">
      <template v-if="filteredItems.length">
        <saved-cards-list :cards="filteredItems" />
      </template>
      <empty-state
        v-else
        size="small"
        title="No Saved Results"
        description="You haven’t saved anything yet. Save your favorite items to view later."
        imageName="SavedResultsPlaceholder.png"
      />
    </div>
    <app-infinite-simple
      :query="$apollo.queries.saved_items"
      sort-key="saved_at"
    />
  </div>
</template>

<script>
import SecondaryHeader from '@/components/general/SecondaryHeader.vue';
import ProfileInformation from '@/components/general/ProfileInformation.vue';
import EmptyState from '@/components/general/EmptyState.vue';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';

import SavedCardsList from '@/components/cards/SavedCardsList.vue';
import listSavedItems from '@/graphql/user/listSavedItems.gql';

import { mapState, mapGetters, mapActions } from 'vuex';
import { onLogout } from '../vue-apollo';
import { analytics } from '@/services/utils';

export default {
  components: {
    SecondaryHeader,
    SavedCardsList,
    ProfileInformation,
    EmptyState,
    AppInfiniteSimple,
  },
  data() {
    return {
      tabs: [{ label: 'Saved' }],
    };
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
    }),
    ...mapGetters('auth', {
      savedItemIdsCache: 'userSavedItemIds',
    }),
    loading() {
      return (
        this.$apollo.queries.saved_items.loading &&
        (!this.saved_items || this.saved_items.length === 0)
      );
    },
    filteredItems() {
      if (!this.saved_items) return null;
      return this.saved_items.filter((item) => {
        return this.savedItemIdsCache.indexOf(item.id) > -1;
      });
    },
  },
  apollo: {
    saved_items: {
      query: listSavedItems,
      variables() {
        return {
          limit: 20,
          saved_at: new Date().toISOString(),
        };
      },
    },
  },
  methods: {
    // TODO: why is there two sets of log out options?  Just call the method in auth and put the other code there
    ...mapActions('auth', {
      logout: 'logout',
    }),
    async logOut() {
      analytics({eventName: 'logout', route: 'profile'})
      await this.logout();
      await onLogout(this.$apollo.getClient());
      this.$router.push('splash');
    },
  },
};
</script>
<style scoped>
.profile-page {
  margin-top: calc(-16px + env(safe-area-inset-top));
  padding-bottom: 16px;
}
.profile-page__tabs {
  margin-top: 16px;
  margin-bottom: 10px;
}
</style>
