import VueRouter from 'vue-router';

import CardAlternate from '../pages/CardAlternate.vue';
import CardDeck from '../pages/CardDeck.vue';
import Splash from '../pages/Splash.vue';
import Signup from '../pages/Signup.vue';
import RequestInvite from '../pages/RequestInvite.vue';
import ConfirmEmail from '../pages/ConfirmEmail.vue';
import Login from '../pages/Login.vue';
import CheckOTP from '../pages/CheckOTP.vue';
import Onboarding from '../pages/Onboarding.vue';
import Profile from '../pages/Profile.vue';
import OthersProfile from '../pages/OthersProfile.vue';
import Tutorial from '../pages/Tutorial.vue';

import { store as $store } from '@/store';

const routes = [
  { path: '/splash', component: Splash, meta: { public: true } },
  { path: '/signup', component: Signup, meta: { public: true } },
  { path: '/request-invite', component: RequestInvite, meta: { public: true } },
  { path: '/confirm-email', component: ConfirmEmail, meta: { public: true } },
  { path: '/login', component: Login, meta: { public: true } },
  { path: '/check-otp', component: CheckOTP, meta: { public: true } },
  { path: '/onboarding', component: Onboarding, meta: { public: true } },
  { path: '/profile', component: Profile, meta: { public: true } },
  {
    path: '/profile/:id',
    component: OthersProfile,
    name: 'OthersProfile',
    meta: { public: true },
  },
  { path: '/tutorial', component: Tutorial, meta: { public: true } },
  {
    path: '/card',
    component: CardDeck,
    meta: { public: false, promptClose: true },
  },
  {
    path: '/alternate',
    component: CardAlternate,
    meta: { public: false, promptClose: true },
  },
  { path: '/', redirect: '/card' },
];

const router = new VueRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  // DDF NOTE: mode history does not work when building for distribution.  Commented out for now.
  // https://stackoverflow.com/questions/56853081/vue-router-not-working-once-production-is-built
  // mode: 'history' ,
  base: __dirname,
  routes, // short for `routes: routes`
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.public) return next();
  const loggedIn = $store.getters['auth/userLoggedIn'];
  if (!loggedIn) return next({ path: '/splash' });
  const onboarded = $store.getters['auth/userOnboarded'];
  if (!onboarded) return next({ path: '/onboarding' });
  return next();
});

export default router;
