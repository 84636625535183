import { getApolloClient } from '@/vue-apollo';
import recommendItems from '@/graphql/item/recommendItems.gql';

export default {
	namespaced: true,
	state: {
		deck: null,
		index: -1,
		loading: false,
		inputs: {},
		userResponses: {},
	},
	getters: {
		all: (state) => state.deck,
		getById: (state) => (id) => {
			return state.deck.find((card) => id === card.id);
		},
		getByIndex: (state) => (index) => {
			return state.deck[index];
		},
		getCurrent: (state) => {
			return state.deck[state.index];
		},
		getCurrentInput: (state, getters) => {
			const current = getters.getCurrent;
			return state.inputs[current.id];
		},
		getCardInput: (state) => (cardId) => {
			return state.inputs[cardId];
		},
		getCardUserResponse: (state) => (cardId) => {
			return state.userResponses[cardId];
		},
	},
	mutations: {
		setDeck(state, val) {
			state.deck = val;
		},
		setLoading(state, val) {
			state.loading = val;
		},
		setIndex(state, val) {
			state.index = val;
		},
		incrementIndex(state) {
			state.index++;
		},
		decrementIndex(state) {
			state.index--;
		},
		setCardInput(state, { card, input }) {
			const inputs = { ...state.inputs };
			inputs[card.id] = input;
			state.inputs = inputs;
		},
		removeCardInput(state, { card }) {
			const inputs = { ...state.inputs };
			delete inputs[card.id];
			state.inputs = inputs;
		},
		setCardUserResponse(state, { card, userResponse }) {
			const userResponses = { ...state.userResponses };
			userResponses[card.id] = userResponse;
			state.userResponses = userResponses;
		},
		removeCardUserResponse(state, { card }) {
			const userResponses = { ...state.userResponses };
			delete userResponses[card.id];
			state.userResponses = userResponses;
		},
	},
	actions: {
		async loadRecommended({ commit }) {
			commit('setLoading', true);
			let { data } = await getApolloClient().query({
				query: recommendItems,
				variables: {
					exceptIds: []
				} 
			})

			console.log(data);

			commit('setLoading', false);
			commit('setDeck', data.recommend_items);
			commit('setIndex', 0);
		},
		async loadNewRecommendedSet({ commit, state }) {
			console.log("loading new set")
			const exceptIds = state.deck.map(e => e.id)
			console.log("except ids ", exceptIds);
			let { data } = await getApolloClient().query({
				query: recommendItems,
				variables: {
					exceptIds: exceptIds
				} 
			})

			const updatedDeck = [...state.deck, ...data.recommend_items]
			console.log(updatedDeck);

			commit('setDeck', updatedDeck);

		},
		setIndex({ commit }, index) {
			commit('setIndex', index);
		},
		nextCard({ commit }) {
			commit('incrementIndex');
		},
		previousCard({ commit }) {
			commit('decrementIndex');
		},
		setCardInput({ commit }, { card, input }) {
			commit('setCardInput', { card, input });
		},
		removeCardInput({ commit }, { card }) {
			commit('removeCardInput', { card });
		},
		setCardUserResponse({ commit }, { card, userResponse }) {
			commit('setCardUserResponse', { card, userResponse });
		},
		removeCardUserResponse({ commit }, { card }) {
			commit('removeCardUserResponse', { card });
		},
	},
};
