import $toast from '@/services/toast';
import router from '@/router/router';
// Allows for await timeout
const promiseTimeout = (duration) => {
	duration = duration || 0;
	return new Promise((resolve) => {
		setTimeout(resolve, duration);
	});
};

const formatDateInterval = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  return (startDate.toLocaleDateString() === endDate.toLocaleDateString()) 
    ? `${startDate.toLocaleString('default', {weekday: 'long'})}, 
        ${startDate.toLocaleDateString('en-uk', {year:"numeric", month:"long", day:"numeric"})} ` 
    : `${startDate.toLocaleDateString('en-uk', {year:"numeric", month:"long", day:"numeric"})} 
        - ${endDate.toLocaleDateString('en-uk', {year:"numeric", month:"long", day:"numeric"})} `;
}

const formatTimeInterval = (start, end) => {
  const startTime = new Date(start);
  const endTime = new Date(end);
  return `${startTime.toLocaleTimeString('default', {hour: 'numeric', minute:'numeric'})} 
          - ${endTime.toLocaleTimeString('default', {hour: 'numeric', minute:'numeric'})} `;
}

const copyToClipboard = (text) => {
  const input = document.createElement('input');
  input.value = text;

  /* Select the text field */
  input.select();
  input.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
  navigator.clipboard.writeText(input.value);
  $toast.show({message: 'Copied!'});
}

// google analytics tracking 
const analytics = ({ eventName, route, label }) =>{
  const category = route ? route : router.currentRoute.path==='/profile' ? 'profile' : 'card-deck';
  
  if(label){
    window.gtag('event', eventName, {
      event_category: category,
      event_label: label,
    });
  }
  else {
    window.gtag('event', eventName, {
      event_category: category,
    });
  }
}

export {
  analytics,
  promiseTimeout,
  formatDateInterval,
  formatTimeInterval,
  copyToClipboard,
}