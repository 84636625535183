<template>
  <div class="event-modal-content">
    <cs-card v-if="event" slot="body">
      <div slot="body">
        <div class="event-detail">
          <div class="event-headline">
            <img :src="event.photo_url" />

            <div class="event-title cs-textstyle-item-heading">
              {{ event ? event.title : '' }}
            </div>
          </div>
          <div class="event-datetime">
            <div class="date cs-textstyle-paragraph-bold">{{ date }}</div>
            <div class="time cs-textstyle-paragraph">{{ time }}</div>
          </div>
          <div class="location cs-textstyle-paragraph">
            <div v-if="event.is_online">Online</div>
            <div v-else>
              {{ event.city.name }}, {{ event.city.country_name }}
            </div>
          </div>
        </div>

        <div class="event-registration-info cs-textstyle-paragraph-bold">
          Thank you for registering to the event! You will shortly receive an
          email with the ticket.
        </div>
        <div class="event-buttons">
          <!-- <cs-button block>Add to calendar</cs-button> -->
          <cs-button
            block
            variant="danger"
            corners="rounded"
            fill="outline"
            @click="cancelRegistration"
          >
            Cancel registration
          </cs-button>
        </div>
      </div>
    </cs-card>
  </div>
</template>

<script>
import { formatDateInterval, formatTimeInterval } from '@/services/utils';
import setEventResponse from '@/graphql/events/setEventResponse.gql';
import $dialog from '@/services/dialog';
import { analytics } from '@/services/utils';

export default {
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    date() {
      return formatDateInterval(this.event.starts_at, this.event.ends_at);
    },
    time() {
      return formatTimeInterval(this.event.starts_at, this.event.ends_at);
    },
    event() {
      return this.item;
    },
  },
  mounted() {
    this.$store.commit('auth/setAttendingEvent', this.event.id);
  },
  methods: {
    async cancelRegistration() {
      analytics({eventName: 'event_cancel-registration'})
      const { data } = await this.$apollo
        .mutate({
          mutation: setEventResponse,
          variables: {
            status: 'cancelled',
            eventId: this.event.id,
          },
        })
        .catch((error) => {
          console.log(error);
        });

      if (data) {
        this.$store.commit('auth/removeAttendingEventId', this.event.id);
        $dialog.alert(
          'You have successfully unregistered from this event. We are looking forward to meeting you next time!'
        );
      } else {
        $dialog.alert('Something went wrong. Please try again.');
      }

      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.event-modal-content .event-detail >>> *,
.event-modal-content .event-registration-info {
  color: var(--cs-gray-06);
}
.event-detail {
  background-color: var(--cs-gray-01);
  padding: 16px;
}
.event-headline {
  display: flex;
  border-bottom: 1px solid var(--cs-gray-02);
  padding-bottom: 16px;
  align-items: center;
}
.event-headline img {
  width: 25%;
  max-height: 54px;
  margin-right: 12px;
}
.event-datetime,
.location {
  margin-top: 16px;
}

.event-registration-info {
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
}
</style>
