<template>
  <div class="tutorial page" :class="`tutorial--focus-${tutorialIndex}`">
    <app-main-header :page-title="pageTitle" :title-icon="titleIcon" />

    <app-card
      class="tutorial__app-card"
      :card="card"
      v-if="config[card.__typename].card_componentName"
    >
      <component
        v-bind:is="config[card.__typename].card_componentName"
        slot="card-content"
        :card="card"
      >
      </component>
    </app-card>

    <app-action-bar
      class="tutorial__action-bar"
      :card="card"
      :back-disabled="true"
      @next-button="nextButton"
      :next-disabled="tutorialIndex === 1"
    />
    <div
      v-if="tutorialIndex !== 0"
      class="tutorial__action-bar-cover"
      @click="nextTutorial"
    ></div>

    <div v-if="currentTutorial" class="tutorial__popover-background"></div>

    <app-tutorial-popover
      v-if="currentTutorial"
      class="tutorial__popover"
      :step="tutorialIndex + 1"
      :total-steps="tutorials.length"
      :message="currentTutorial.message"
      @skip="skipTutorial"
      @next="nextTutorial"
      :pointer-position="currentTutorial.pointer"
    />
  </div>
</template>
<script>
import AppCard from '../components/cards/Card.vue';
import PersonCard from '../components/cards/PersonCard.vue';
import AppMainHeader from '../components/general/MainHeader.vue';
import AppActionBar from '../components/general/ActionBar.vue';
import AppTutorialPopover from '@/components/tutorials/Popover.vue';

import { itemConfig } from '@/services/cardConfig';
import { analytics } from '@/services/utils';

const samplePersonCard = {
  __typename: 'Person',
  id: '12345678-1234-1234-1234-123456789012',
  first_name: 'Micheal',
  last_name: 'Johndas',
  occupation: 'Chief Imaginary Officer at C-Suite Circle',
  photo_url: require('@/assets/img/tutorial-profile-picture.png'),
  city: 'Singapore',
  summary:
    'CIO at Leaders.org - A global network of influential leaders who help each other succeed.',
  interests: [
    { interest: 'leading', __typename: 'PersonInterest' },
    { interest: 'learning', __typename: 'PersonInterest' },
    { interest: 'teamwork', __typename: 'PersonInterest' },
    { interest: 'networking', __typename: 'PersonInterest' },
  ],
  interested_leaders: [],
};

const tutorials = [
  {
    message: `We'll show you cards about interesting people, events and articles that can help you succeed.`,
    pointer: 'none',
  },
  {
    message: `Tap the main button to interact with the card.`,
    pointer: 'bottom-center',
  },
  {
    message: `Explore more cards by tapping next.`,
    pointer: 'bottom-right',
  },
];

export default {
  components: {
    AppCard,
    PersonCard,
    AppMainHeader,
    AppActionBar,
    AppTutorialPopover,
  },
  computed: {
    currentConfig() {
      return this.config[this.card.__typename];
    },
    pageTitle() {
      if (!this.currentConfig) return '';
      return this.currentConfig.card_title;
    },
    titleIcon() {
      if (!this.currentConfig) return '';
      return this.currentConfig.card_icon;
    },
    buttonLabel() {
      if (!this.currentConfig) return '';
      return this.currentConfig.card_primaryButtonLabel;
    },
    currentTutorial() {
      return this.tutorials[this.tutorialIndex];
    },
  },
  data() {
    return {
      card: samplePersonCard,
      config: itemConfig,
      tutorials: tutorials,
      tutorialIndex: 0,
    };
  },
  methods: {
    executePrimaryAction() {
      this.nextTutorial();
    },
    nextButton() {
      this.nextTutorial();
    },
    nextTutorial() {
      this.tutorialIndex++;
      if (this.tutorialIndex >= this.tutorials.length) {
        analytics({eventName: 'tutorial-complete', route: 'card-deck'})
        this.skipTutorial();
      }
    },
    skipTutorial() {
      if (this.tutorialIndex < this.tutorials.length) {
        analytics({eventName: 'tutorial-skip', route: 'card-deck'})
      }
      this.tutorialIndex = this.tutorials.length;
      this.$store.dispatch('auth/onboardUser');
      this.$router.replace({ path: '/' });
    },
  },
};
</script>
<style scoped>
.tutorial.page .tutorial__app-card {
  overflow-y: hidden;
  ---main-header-height: calc(30px + 16px + 16px + 8px);
  ---main-footer-height: calc(40px + 16px + 16px);
  padding-top: calc(env(safe-area-inset-top) + var(---main-header-height));
  padding-bottom: calc(
    env(safe-area-inset-bottom) + var(---main-footer-height)
  );
  padding-left: var(--cs-page-margin-x);
  padding-right: var(--cs-page-margin-x);
}

.tutorial__action-bar-cover {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px var(--cs-page-margin-x);
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
  opacity: 0;
  z-index: 2;
  height: 40px;
}
.tutorial.page .action-bar {
  pointer-events: none;
  background-color: var(--cs-page-background-color);
  z-index: 2;
}
.tutorial.page .action-bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--cs-gray-08);
  opacity: 0.3;
}

.tutorial--focus-0 .tutorial__app-card {
  position: relative;
  z-index: 2;
}
.tutorial--focus-1.tutorial.page .action-bar,
.tutorial--focus-2.tutorial.page .action-bar {
  background-color: unset;
  z-index: 2;
}
.tutorial--focus-1.tutorial.page .action-bar::after,
.tutorial--focus-2.tutorial.page .action-bar::after {
  content: none;
}

.tutorial__popover-background {
  z-index: 1;
  background-color: var(--cs-gray-08);
  opacity: 0.3;
  position: fixed;
  top: -200px;
  left: -200px;
  right: -200px;
  bottom: -200px;
}

/* POPOVER POSITIONS */
/* First popover */
.tutorial--focus-0 .tutorial__popover {
  top: calc(16px + env(safe-area-inset-top));
  left: 50%;
  transform: translateX(-50%);
}
/* Second popover */
.tutorial--focus-1 .tutorial__popover {
  /* footer height */
  bottom: calc(40px + 16px + 16px + env(safe-area-inset-bottom));
  left: 50%;
  transform: translateX(-50%);
}
/* Third popover */
.tutorial--focus-2 .tutorial__popover {
  /* footer height */
  bottom: calc(40px + 16px + 16px + env(safe-area-inset-bottom));
  right: 16px;
}
</style>
