import $inappbrowser from '@/services/inappbrowser';
import $dialog from '@/services/dialog';

import getArticle from '@/graphql/articles/getArticle.gql';

const articleConfig = {
  card_componentName: 'ArticleCard',
  card_title: 'Article',
  card_icon: 'cs-icons-news',
  card_primaryButtonLabel: 'Read',
  card_hasMediaSlot: true,
  card_item_query: getArticle,
  card_item_query_key: 'article',
  gtag_label: 'article-card',

  share_props: {
    title: 'title',
    message: 'content_snippet',
  },

  primaryCardAction: async (card) => {
    if (!card.link) return $dialog.alert('No link found for this article!');
    $inappbrowser.open(card.link);
  },
};

export default articleConfig;
