<template>
	<div class="main-header">
		<div class="main-header__background-color"></div>
		<div class="main-header__row">
			<div class="page-title cs-textstyle-page-title">
				<i v-if="titleIcon" :class="titleIcon"></i>
				{{ pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1) }}
			</div>
			<div v-if="user" @click="$router.push('profile')">
				<cs-avatar
					size="small"
					:picture="user.photo_url"
					:name="`${user.first_name} ${user.last_name}`"
				/>
			</div>
		</div>
		<slot name="action-buttons"></slot>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		titleIcon: {
			type: String,
			default: '',
		},
		pageTitle: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapState('auth', {
			user: 'user',
		}),
	},
};
</script>

<style scoped>
.main-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 16px var(--cs-page-margin-x);
	padding-top: calc(16px + env(safe-area-inset-top));
	min-height: 30px;
	z-index: 1;
	backdrop-filter: blur(10px);
}
.main-header__background-color {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--cs-page-background-color);
	opacity: 0.5;
}
.main-header__row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.page-title {
	position: relative;
	color: var(--cs-primary-base);
}
.page-title i {
	font-size: 24px;
}
</style>
