import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';

import 'cs-components';
import 'cs-components/dist/csuite.css';
import '@/styles/variables.css';
import '@/styles/mobile-fixes.css';
import '@/styles/global.css';
import '@/styles/dialog.css';
import '@/styles/toast.css';

import '@/services/theme';
import '@/services/androidback';

import router from './router/router';
import { createProvider } from './vue-apollo';
import { store } from './store';

// Used to detect when items move into view
import VueObserveVisibility from 'vue-observe-visibility';

import './registerServiceWorker';
Vue.use(VueObserveVisibility);

Vue.use(VueRouter);

Vue.config.productionTip = false;

new Vue({
  store: store,
  apolloProvider: createProvider(),
  router,
  render: (h) => h(App),
}).$mount('#app');
