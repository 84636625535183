<template>
  <div class="process-bar">
    <span class="cs-textstyle-paragraph-small-bold"> Question {{ step }} of {{ totalStep }} </span>
    <div class="progress-bar-wrapper">
      <div
        v-for="index in totalStep"
        :key="index"
        class="progress-bar"
        :class="index > step ? 'completed-bar' : ''"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
    totalStep: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.process-bar {
  padding-bottom: 16px;
  color: var(--cs-gray-05);
}
.progress-bar {
  background-color: var(--cs-primary-base);
  width: 75px;
  margin-top: 8px;
  margin-right: 5px;
  height: 4px;
  border-radius: 5px
}
.completed-bar {
  background-color: var(--cs-gray-02);
}
.progress-bar-wrapper {
  display: flex;
}
</style>
